// different action types for the different functions

/* * * * * * * * *
 * ITEM SPECIFIC *
 * * * * * * * * */
// Fetch Conditional requests that only indicate if it was success or fail
export const FETCH_CONDITION_START = 'FETCH_CONDITION_START';
export const FETCH_CONDITION_SUCCESS = 'FETCH_CONDITION_SUCCESS';
export const FETCH_CONDITION_FAIL = 'FETCH_CONDITION_FAIL';
// Fetch List/Group of...
export const FETCH_LIST_START = 'FETCH_LIST_START';
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAIL = 'FETCH_LIST_FAIL';
// Fetch paged list of users...
export const FETCH_PAGED_START = 'FETCH_PAGED_START';
export const FETCH_PAGED_SUCCESS = 'FETCH_PAGED_SUCCESS';
export const FETCH_PAGED_FAIL = 'FETCH_PAGED_FAIL';
// Fetch details of user...
export const FETCH_DETAILS_START = 'FETCH_DETAILS_START';
export const FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAIL = 'FETCH_DETAILS_FAIL';
// Fetch planboard items and bookings
export const FETCH_PLANBOARD_ITEMS_START = 'FETCH_PLANBOARD_ITEMS_START';
export const FETCH_PLANBOARD_ITEMS_SUCCESS = 'FETCH_PLANBOARD_ITEMS_SUCCESS';
export const FETCH_PLANBOARD_ITEMS_FAIL = 'FETCH_PLANBOARD_ITEMS_FAIL';
// Fetch planboard items and bookings
export const FETCH_BLOB_START = 'FETCH_BLOB_START';
export const FETCH_BLOB_SUCCESS = 'FETCH_BLOB_SUCCESS';
export const FETCH_BLOB_FAIL = 'FETCH_BLOB_FAIL';

// Filter related resources to be reused in different components
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';

//Login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

//Logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

/* * * * * * * * *
 * VIEW SPECIFIC * 
 * * * * * * * * */
export const SET_GLOBAL_STATE = 'SET_GLOBAL_STATE';
export const UPDATE_REDIRECT_OBJECT = 'UPDATE_REDIRECT_OBJECT';

/* * * *
 * UI  *
 * * * */
export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';

/* * * * * * * * *
 * LOCALIZATION  *
 * * * * * * * * */
export const SET_LOCALIZATION = 'SET_LOCALIZATION';