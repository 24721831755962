import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	item: {
		display: 'block',
		paddingTop: 0,
		paddingBottom: 0
	},
	itemLeaf: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0
	},
	buttonContainer: {
		flexGrow: 1,
		height: '100%'
	},
	button: {
		color: theme.palette.primary.light,
		padding: '10px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%'
	},
	buttonLeaf: {
		color: theme.palette.primary.light,
		padding: '10px 8px',
		justifyContent: 'center',
		textTransform: 'none',
		letterSpacing: 0,
		height: '100%',
		width: 200,
		fontWeight: 500,
		borderRadius: 'unset',
	},
	icon: {
		color: theme.palette.icon,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	expandIcon: {
		marginLeft: 'auto',
		height: 16,
		width: 16
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto'
	},
	active: {
		color: theme.palette.primary.main,
		fontWeight: 600,
		borderBottom: `2px solid ${theme.palette.primary.main}`,
		'& $icon': {
			color: theme.palette.primary.main
		}
	}
}));