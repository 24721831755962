import PropTypes from 'prop-types';
import { Typography, Paper } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { translate } from '../../../shared/utility';
import { useStyles } from './style';

const EmptyTable = props => {

	const { language, label } = props;

	const classes = useStyles();

	return (
		<Paper className={classes.tipCard} >
			<InfoOutlinedIcon className={classes.icon} fontSize='small' />
			<Typography className={classes.tip} >
				{translate(label, language)}
			</Typography>
		</Paper>
	);
};

EmptyTable.propTypes = {
	language: PropTypes.string,
	label: PropTypes.string
};

export default EmptyTable;
