import { translate } from '../../../../shared/utility';

export const navigationConfig = language => [
	{
		title: translate('nav.title', language),
		pages: [
			{
				title: translate('nav.button.planboard', language),
				href: '/planboard',
			},
			{
				title: translate('nav.button.bookings', language),
				href: '/bookings',
			},
			{
				title: translate('nav.button.myTrips', language),
				href: '/trips',
			},
		]
	}
];
