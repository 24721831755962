import available from '../data/translations/_.json';

export const availableLocales = available;

export const availableLanguages = availableLocales.map(({ language }) => language);

export const getDefaultLocale = () => availableLocales.find(locale => locale.isDefault);

export const getLocale = localeString => {
	localeString = localeString || navigator.language || (navigator.languages ? navigator.languages[0] : undefined) || navigator.userLanguage;

	return availableLocales.find(locale => locale.id === localeString) || getDefaultLocale();
};

export const translations = availableLocales.reduce((obj, locale) => ({
	...obj,
	[locale.language]: require(`../data/translations/${locale.language}.json`)
}), {});