import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';

import { LinearProgress } from '@mui/material';
import Topbar from './Topbar';

import { useStyles } from './style';

const Auth = props => {
	const { route } = props;

	const classes = useStyles();

	return (
		<>
			<Topbar />
			<main className={classes.content}>
				<Suspense fallback={<LinearProgress />}>
					{renderRoutes(route.routes)}
				</Suspense>
			</main>
		</>
	);
};

Auth.propTypes = {
	route: PropTypes.object
};

export default Auth;