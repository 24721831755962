import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	content: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		'&:last-child': {
			paddingBottom: theme.spacing(2)
		},
		display: 'flex'
	},
	closeButton: {
		alignSelf: 'flex-start',
		marginLeft: 'auto',
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1)
	},
	body3: {
		...theme.typography.body3
	},
	avatarContainer: {
		marginRight: theme.spacing(1.5)
	}
}));