import { FETCH_CONDITION_START, FETCH_CONDITION_SUCCESS, FETCH_CONDITION_FAIL } from './actionTypes';
import { fetchDetailsSuccess } from './details';
import { updateRedirect } from './global';
import events from './eventServices';
import { isObject } from '../../shared/utility';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchConditionStart = identifier => {
	return {
		type: FETCH_CONDITION_START,
		identifier: identifier
	};
};

export const fetchConditionSuccess = (identifier, value = true) => {
	return {
		type: FETCH_CONDITION_SUCCESS,
		identifier: identifier,
		value: value
	};
};

export const fetchConditionFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
	return {
		type: FETCH_CONDITION_FAIL,
		identifier: identifier,
		error: error
	};
};

export const resetStateCondition = (identifier, value) => {
	return dispatch => {
		dispatch(fetchConditionSuccess(identifier, value));
	};
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type
// according the state of the request

/* * * * * * * * * *
 * BOOKING METHODS *
 * * * * * * * * * */
export const cancelBooking = (bookingId = null, redirect = null) => {
	return async dispatch => {
		dispatch(fetchConditionStart('canceledBooking'));
		try {
			await events.put(`bookings/${bookingId}/cancel`);
			dispatch(fetchConditionSuccess('canceledBooking', true));
			if (redirect) {
				dispatch(updateRedirect('/bookings'));
			}
		} catch (error) {
			dispatch(fetchConditionFail('canceledBooking', error));
		}
	};
};

/* * * * * * * * * *
 * BOOKING METHODS *
 * * * * * * * * * */
export const patchUpdateBooking = (bookingId = null, properties = null) => {
	const bodyData = isObject(properties) ? Object.entries(properties).map(prop => ({ value: prop[1], path: `/${prop[0]}`, op: 'replace' })) : {};
	return async dispatch => {
		dispatch(fetchConditionStart('updatedBookingDetails'));
		try {
			const data = await events.patch(`planboard/${bookingId}`, bodyData);
			dispatch(fetchDetailsSuccess('fetchBooking', data));
			dispatch(fetchConditionSuccess('updatedBookingDetails', true));
		} catch (error) {
			dispatch(fetchConditionFail('updatedBookingDetails', error));
		}
	};
};

export const patchTrip = (tripId = null, bodyData = null) => {

	return async dispatch => {
		dispatch(fetchConditionStart('patchTrip'));
		try {
			await events.put(`trips/${tripId}/distance`, bodyData);
			dispatch(fetchConditionSuccess('patchTrip', true));
		} catch (error) {
			dispatch(fetchConditionFail('patchTrip', error));
		}
	};
};