import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './style';

const Bar = props => {
	const classes = useStyles();
	return <span className={clsx(props.className, classes.loadingBar)} style={{ ...props.style }} />;
};

Bar.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string
};

export default Bar;