import { SET_GLOBAL_STATE, UPDATE_REDIRECT_OBJECT, SET_LOCALIZATION, SET_WINDOW_DIMENSIONS } from './actionTypes';
import { isFullString, getLanguageCode, isNull } from '../../shared/utility';
import { availableLocales, getLocale } from '../../shared/translations';

/* * * * * * * * * * *
 * SET GLOBAL STATE  *
 * * * * * * * * * * */
export const setGlobalState = (identifier = null, value) => {
	return {
		type: SET_GLOBAL_STATE,
		identifier: identifier,
		value: value,
	};
};

export const updateRedirect = (pathname = null, search = null) => {
	return {
		type: UPDATE_REDIRECT_OBJECT,
		pathname: pathname,
		search: search
	};
};

/* * * * * * * *
 * UI METHODS  *
 * * * * * * * */
export const setWindowDimensions = () => ({
	type: SET_WINDOW_DIMENSIONS,
	windowDimensions: {
		width: window.innerWidth,
		height: window.innerHeight
	}
});

/* * * * * * * * * * * * *
 * LOCALIZATION METHODS  *
 * * * * * * * * * * * * */
export const setLocalization = localeString => {
	if (isFullString(localeString) && localeString !== getLanguageCode()) {
		localStorage.removeItem('languageCode');
		localStorage.setItem('languageCode', localeString);
	} else if (isNull(getLanguageCode())) {
		localStorage.setItem('languageCode', getLocale(localeString).id);
	}
	const locale = getLocale(getLanguageCode());
	return {
		type: SET_LOCALIZATION,
		available: availableLocales,
		locale: locale.id,
		language: locale.language,
		region: locale.region
	};
};