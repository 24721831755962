import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			borderRadius: 50,
			textTransform: 'none',
		},
		contained: {
			boxShadow:
				'0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
			backgroundColor: '#FFFFFF',
			'&.Mui-disabled': {
				backgroundColor: palette.primary.main,
				opacity: 0.7,
				color: palette.primary.contrastText
			}
		},
		containedPrimary: { //button variant: contained-primary
			backgroundColor: palette.button.primary,
			color: palette.button.contrastText.primary,
			'&:hover': {
				backgroundColor: palette.button.primary,
				color: palette.button.contrastText.primary,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.button.primaryDisabled,
				color: palette.button.contrastText.primaryDisabled,
			}
		},
		containedSecondary: { //button variant: contained-secondary
			backgroundColor: palette.button.secondary,
			color: palette.button.contrastText.secondary,
			'&:hover': {
				backgroundColor: palette.button.secondary,
				color: palette.button.contrastText.secondary,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.button.secondaryDisabled,
				color: palette.button.contrastText.secondaryDisabled,
			}
		},
		outlined: {
			borderColor: palette.primary.main,
			'&.Mui-disabled': {
				color: palette.primary.main,
				borderColor: palette.primary.main,
				opacity: 0.75
			}
		},
		outlinedPrimary: { //button variant: contained-tertiary
			color: palette.button.secondary,
			borderColor: palette.button.outline,
			'&:hover': {
				borderColor: palette.button.secondary
			},
			'&.Mui-disabled': {
				color: palette.button.secondaryDisabled,
				borderColor: palette.button.outline,
			}
		},
		outlinedSecondary: { //button variant: contained-delete
			color: palette.button.deleteColor,
			borderColor: palette.button.outline,
			'&:hover': {
				borderColor: palette.button.deleteColor
			},
			'&.Mui-disabled': {
				color: palette.button.deleteColorDisabled,
				borderColor: palette.button.outline,
			}
		},
		textPrimary: { //button variant: inline-default
			color: palette.button.secondary,
			'&:hover': {
				backgroundColor: palette.button.outline
			},
			'&.Mui-disabled': {
				color: palette.button.secondaryDisabled,
			}
		},
		textSecondary: { //button variant: inline-delete
			color: palette.button.deleteColor,
			'&:hover': {
				backgroundColor: palette.button.outline
			},
			'&.Mui-disabled': {
				color: palette.button.deleteColorDisabled,
			}
		}
	}
};
