import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(3),
		gap: theme.spacing(1, 1)
	},
	searchFilterContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		gap: theme.spacing(1, 2)
	},
	extraButtons: {
		alignSelf: 'flex-start'
	},
	search: {
		width: theme.spacing(35),
		padding: 0,
		margin: 0
	},
	filterButton: {
		marginLeft: 'auto'
	},
	filterIcon: {
		marginRight: theme.spacing(1)
	},
	pushRight: {
		marginRight: 'auto'
	},
	filters: {
		display: 'flex'
	},
	mainFilter: {
		marginRight: theme.spacing(2),
	},
	timePop: {
		padding: '9px 10px',
		display: 'flex',
		alignItems: 'center',
		borderRadius: 50
	},
	extraButtonsContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: theme.spacing(1, 1)
	},
}));