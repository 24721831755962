import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import { Label } from '../../../components';

export const useStyles = makeStyles(() => ({
	header: {
		paddingRight: '8px'
	},
}));
const HeaderWithStatus = props => {

	const { justifyContent, header, headerSize, subheader, subheaderSize, type, status, text, variantText } = props;
	const classes = useStyles();

	return (
		<Box display='flex' flexDirection='column'>
			<Box display='flex' justifyContent={justifyContent}>
				<Typography
					className={classes.header}
					variant={headerSize}
				>
					{header}
				</Typography>
				{status ?
					<Label type={type}>
						{status}
					</Label> :
					<Typography
						variant={variantText}
					>{text}
					</Typography>
				}
			</Box>
			<Typography variant={subheaderSize}>
				{subheader}
			</Typography>
		</Box>
	);
};

HeaderWithStatus.propTypes = {
	justifyContent: PropTypes.string,
	header: PropTypes.string,
	headerSize: PropTypes.string,
	subheader: PropTypes.string,
	subheaderSize: PropTypes.string,
	type: PropTypes.string,
	status: PropTypes.string,
	text: PropTypes.string,
	variantText: PropTypes.string
};

export default HeaderWithStatus;