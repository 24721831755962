import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {},
	filterButton: {
		marginRight: theme.spacing(2)
	},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 700
	},
	actions: {
		padding: theme.spacing(0, 1),
		justifyContent: 'flex-end'
	},
	collapsButtonCell: {
		paddingRight: '8px',
	}
}));