import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { translate, isFullString, isUndefined, isEmptyObject } from '../../../shared/utility';

import { TextField, InputAdornment } from '@mui/material';
import MaxLengthCountDown from './MaxLengthCountDown';

const FormField = props => {
	const { className, label, name, variable, maxLength, hideCharacterCounter, isExtraValid, extraValidHelperText, required, language, ...rest } = props;

	const lengthProperties = maxLength ? {
		inputProps: {
			maxLength
		},
		...(!hideCharacterCounter && {
			InputProps: {
				endAdornment: (
					<InputAdornment position='end'>
						<MaxLengthCountDown length={variable?.value?.length} maxLength={maxLength} />
					</InputAdornment>
				)
			}
		})
	} : {};

	return (
		<TextField
			autoFocus={false}
			className={className}
			error={variable.isTouched && !variable.hasFocus && ((!variable.isValid && !isEmptyObject(variable.validationError)) || (!isUndefined(isExtraValid) && !isExtraValid))}
			fullWidth
			helperText={variable.isTouched && !variable.hasFocus && ((!variable.isValid && !isEmptyObject(variable.validationError)) || (!isUndefined(isExtraValid) && !isExtraValid)) ? isFullString(variable.value) && (!isUndefined(isExtraValid) && !isExtraValid) && isEmptyObject(variable.validationError) ? extraValidHelperText : translate(`ui.error.message.${variable.validationError.key}`, language) : null}
			label={label}
			name={name}
			required={required}
			variant="outlined"
			{...variable.bindToFormField}
			InputLabelProps={{
				shrink: true,
			}}
			{...lengthProperties}
			FormHelperTextProps={{
				style: {
					position: 'absolute',
					top: '100%',
					marginLeft: 0
				}
			}}
			{...rest}
		/>
	);
};

FormField.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	variable: PropTypes.object,
	maxLength: PropTypes.number,
	hideCharacterCounter: PropTypes.bool,
	isExtraValid: PropTypes.bool,
	extraValidHelperText: PropTypes.string,
	required: PropTypes.bool,
	language: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FormField);