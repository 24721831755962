import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		backgroundColor: '#FFFFFF',
		boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
	},
	content: {
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			flexWrap: 'wrap'
		},
		'&:last-child': {
			paddingBottom: theme.spacing(2)
		}
	},

	stats: {
		width: 100,
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			flexBasis: '50%'
		}
	},

	header: {
		width: 100,
	}
}));