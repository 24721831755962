/* * * * * * * * * * * * *
 * FORM INPUT VALIDATION *
 * * * * * * * * * * * * */
import { isUndefined, isEmptyString, isFullString, isFullArray, isEmptyObject } from './utility';

const FORBIDDEN_PASSWORDS = ['123456', '123456789', 'qwerty', '12345678', '111111', '1234567890', '1234567', 'password', '123123', '987654321', 'qwertyuiop', 'mynoob', '123321', '666666', '18atcskd2w', '7777777', '1q2w3e4r', '654321', '555555', '3rjs1la7qe', 'google', '1q2w3e4r5t', '123qwe', 'zxcvbnm', '1q2w3e'];

const toLowerCaseArray = input => [].concat(input).map(str => `${str}`.toLowerCase());

export const validateInput = (value, rules) => {
	if (!rules || (!rules.required && isEmptyString(value))) {
		return {
			valid: true,
			error: {}
		};
	}

	const availableRules = [
		{
			name: 'required',
			test: val => isFullString(val.toString())
		},
		{
			name: 'minLength',
			test: val => val.length >= rules.minLength
		},
		{
			name: 'maxLength',
			test: val => val.length <= rules.maxLength
		},
		{
			name: 'isEmail',
			test: val => {
				// const pattern = /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        const pattern = /^([\w\d\.\+-])+@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; //eslint-disable-line
				return pattern.test(val);
			}
		},
		{
			name: 'isUrl',
			test: val => {
				const pattern = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
				return pattern.test(val);
			}
		},
		{
			name: 'isNumeric',
			test: val => {
				const pattern = /^\d+$/;
				return pattern.test(val);
			}
		},
		{
			name: 'minAmount',
			test: val => val >= rules.minAmount
		},
		{
			name: 'maxAmount',
			test: val => val <= rules.maxAmount
		},
		{
			name: 'isNumAndLetter',
			test: val => {
				const pattern = /^\d+([-]\d*)*/;
				return pattern.test(val);
			}
		},
		{
			name: 'isPassword',
			test: val => {
				// not one of the top 25 common passwords
				return !FORBIDDEN_PASSWORDS.includes(val);
			}
		},
		{
			name: 'isHex',
			test: val => {
				const pattern = /^#[0-9A-F]{6}$/i;
				return pattern.test(val);
			}
		},
		{
			name: 'isUUID',
			test: val => {
				const pattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
				return pattern.test(val);
			}
		},
		{
			name: 'isVIN',
			test: val => {
				const pattern = /[0-9A-Z]{11}[0-9]{6}/;
				return pattern.test(val);
			}
		},
		{
			name: 'isDutchNumberPlate',
			test: val => {
				const pattern = /([A-Z]{2}-[0-9]{2}-[0-9]{2})|([0-9]{2}-[0-9]{2}-[A-Z]{2})|([0-9]{2}-[A-Z]{2}-[0-9]{2})|([A-Z]{2}-[0-9]{2}-[A-Z]{2})|([0-9]{2}-[A-Z]{2}-[A-Z]{2})|([A-Z]{2}-[A-Z]{2}-[0-9]{2})|([0-9]{2}-[A-Z]{3}-[0-9]{1})|([0-9]{1}-[A-Z]{3}-[0-9]{2})|([A-Z]{2}-[0-9]{3}-[A-Z]{1})|([A-Z]{1}-[0-9]{3}-[A-Z]{2})|([A-Z]{3}-[0-9]{2}-[A-Z]{1})/;
				return pattern.test(val);
			}
		},
		{
			name: 'is',
			test: val => {
				if (!isFullString(rules.is)) {
					return true;
				}
				return val === rules.is;
			}
		},
		{
			name: 'isNot',
			test: val => {
				if (!isFullArray(rules.isNot) && !isFullString(rules.isNot)) {
					return true;
				}
				const isNot = toLowerCaseArray(rules.isNot);
				return !isNot.includes(`${val}`.toLowerCase());
			}
		},
		{
			name: 'contains',
			test: val => {
				if (!isFullArray(rules.contains) && !isFullString(rules.contains)) {
					return true;
				}
				const containsValues = toLowerCaseArray(rules.contains);

				let contains = false;
				containsValues.forEach(test => {
					if (val.toLowerCase().includes(test)) {
						contains = true;
					}
				});

				return contains;
			}
		},
		{
			name: 'containsNot',
			test: val => {
				if (!isFullArray(rules.containsNot) && !isFullString(rules.containsNot)) {
					return true;
				}
				const containsNotValues = [].concat(rules.containsNot).map(str => `${str}`.toLowerCase());

				let contains = false;
				containsNotValues.forEach(test => {
					if (val.toLowerCase().includes(test)) {
						contains = true;
					}
				});

				return !contains;
			}
		},
		{
			name: 'isDutchAddress',
			test: val => {
				const pattern = /^([1-9][e][\s])*([a-zA-Z]+(([.][\s])|([\s]))?)+[1-9][0-9]*(([-][1-9][0-9]*)|([\s]?[a-zA-Z]+))?$/i;
				return pattern.test(val);
			}
		},
		{
			name: 'isDutchPostcode',
			test: val => {
				const pattern = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
				return pattern.test(val);
			}
		},
		{
			name: 'isDutchMobileNumber',
			test: val => {
				const pattern_vast = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
				const pattern_mobiel = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
				return (pattern_vast.test(val) || pattern_mobiel.test(val));
			}
		},
		{
			name: 'isDutchOrBelgianPhoneNumber',
			test: val => {
				const pattern_dutch_regional = /^(((\+|00)31[ ]{0,1})|0)(([0-9]{2}[- ]{0,1}[0-9]{3}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2})|([0-9]{3}[- ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2}))$/i;
				const pattern_dutch_mobile = /^(((\+|00)31[ ]{0,1})|0)(6[- ]{0,1}[1-9]{1}[0-9]{1}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2})$/i;
				const pattern_belgian_regional = /^(((\+|00)32[ ]{0,1})|0)(([0-9]{1}[- ]{0,1}[0-9]{3}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2})|([0-9]{2}[- ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2}))$/i;
				const pattern_belgian_mobile = /^(((\+|00)32[ ]{0,1})|0)(4[0-9]{2}[- ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2}[ ]{0,1}[0-9]{2})$/i;
				return (pattern_dutch_regional.test(val) || pattern_dutch_mobile.test(val) || pattern_belgian_regional.test(val) || pattern_belgian_mobile.test(val));
			}
		},
		{
			name: 'isDutchBankNumber',
			test: val => {
				const pattern = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i;
				return pattern.test(val);
			}
		},
		{
			name: 'isVatNumber',
			test: val => {
				const pattern = /^((AT)?U[0-9]{8}|(BE)?[ ]{0,1}[01]{1}[0-9]{3}[.]{0,1}[0-9]{3}[.]{0,1}[0-9]{3}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;
				return pattern.test(val);
			}
		},
		{
			name: 'isDutchIbanNumber',
			test: val => {
				const pattern = /^NL\d{2}[A-Z]{4}\d{10}$/;
				return pattern.test(val.replace(/[ ]/g, ''));
			}
		},
		{
			name: 'isIbanNumber',
			test: val => {
				const pattern_dutch = /^NL\d{2}[A-Z]{4}\d{10}$/;
				const pattern_belgian = /^BE\d{14}$/;
				return pattern_dutch.test(val.replace(/[ ]/g, '')) || pattern_belgian.test(val.replace(/[ ]/g, ''));
			}
		},
		{
			name: 'isEnterpriseNumber',
			test: val => {
				const pattern = /^[01]{1}[0-9]{3}[.]{0,1}[0-9]{3}[.]{0,1}[0-9]{3}$/;
				return pattern.test(val);
			}
		},
		{
			name: 'isFullObject',
			test: val => !isEmptyObject(val)
		},
		{
			name: 'custom',
			test: rules.custom
		}
	];

	let isValid = true;

	let error = {};

	availableRules.forEach(({ name, test }) => {
		if (!isUndefined(rules[name]) && !test(value)) {
			isValid = false;
			if (isEmptyObject(error)) {
				error = {
					key: name
				};
			}
		}
	});

	return {
		valid: isValid,
		error
	};
};

