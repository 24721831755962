import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
	Card,
	CardContent,
	IconButton,
	Box,
	Typography,
} from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export const useStyles = makeStyles(() => ({
	root: {},
	content: {
		padding: 0,
		paddingBottom: '0px'
	},
	imgCard: {
		padding: '8px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: '0px'
		}
	},
	imageContainer: {
		height: '69px',
		width: '94px'
	},
	imageSpacing: {
		objectFit: 'scale-down',
		width: '100%',
		height: '100%',
		margin: '0',
		padding: '0'
	},
	iconButton: {
		padding: '0px'
	},
}));

const ItemCard = props => {

	const { image, name, instanceName, addressIcon, address, locationIcon, iconButton, locationName, handleItemView } = props;

	const classes = useStyles();

	return (
		<Card className={classes.imgCard}>
			<CardContent className={classes.content}>
				<Box alignItems='center' display='flex' justifyContent='space-between'>
					<Box alignItems='center' display='flex'>
						<div className={classes.imageContainer}>
							{image ? <img className={classes.imageSpacing} src={image} /> : null}
						</div>
						<Box display='flex' flexDirection='column' pl={0.5}>
							<Box pl={0.5}>
								<Typography>{name}</Typography>
							</Box>
							<Box pl={0.5}>
								<Typography color='inherit'>{instanceName}</Typography>
							</Box>
							<Box alignItems='center' display='flex'>
								{addressIcon}
								<Typography variant='body2'>{address}</Typography>
							</Box>
							<Box alignItems='center' display='flex'>
								{locationIcon}
								<Typography variant='body2'>{locationName}</Typography>
							</Box>
						</Box>
					</Box>
					{iconButton ?
						<IconButton
							className={classes.iconButton}
							disableRipple={true}
							onClick={handleItemView}
						>
							<ArrowForwardIosIcon fontSize='small' />
						</IconButton>
						: null}
				</Box>

			</CardContent>
		</Card>
	);
};

ItemCard.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string,
	name: PropTypes.string,
	instanceName: PropTypes.string,
	locationIcon: PropTypes.object,
	addressIcon: PropTypes.object,
	address: PropTypes.string,
	iconButton: PropTypes.bool,
	locationName: PropTypes.string,
	handleItemView: PropTypes.func
};

export default ItemCard;