import PropTypes from 'prop-types';
import clsx from 'clsx';
import MoreButton from '../../../UI/MoreButton/MoreButton';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useStyles } from './style';


const DownloadButton = props => {
	const { downloadData } = props;

	const classes = useStyles();

	return (
		<div className={clsx(classes.root)}>
			<MoreButton
				disabled={downloadData.disabled}
				menuItems={downloadData.action()}
				startIcon={<InsertDriveFileOutlinedIcon />}
				text={downloadData.text}
			/>
		</div>
	);
};

DownloadButton.propTypes = {
	downloadData: PropTypes.object
};

export default DownloadButton;