/* eslint-disable react/display-name */
import { useEffect, useRef, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { isObject, isNull, translate, getInitials, isUndefined, isFunction, isFullString } from '../../../shared/utility';
import { useDebounce } from '../../../shared/hooks';
import { TextField, Popper, Paper, List, ListItem, Avatar, Box, Typography, ClickAwayListener } from '@mui/material';
import { LoadingBar } from '../../loading';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useStyles } from './style';
import ScheduleIcon from '@mui/icons-material/Schedule';
const propsAreEqual = (prevProps, nextProps) => {
	return prevProps.value === nextProps.value && prevProps.className === nextProps.className && JSON.stringify(prevProps.dataList) === JSON.stringify(nextProps.dataList)
		&& JSON.stringify(prevProps.filter) === JSON.stringify(nextProps.filter) && JSON.stringify(prevProps.extraFilter) === JSON.stringify(nextProps.extraFilter);
};

const SelectWithLazyLoading = props => {
	const { className, placeholder, value, emptyStateText, dataList, onFetchData, setSelected, listType, defaultListItem, filter, extraFilter, fetchById, shouldNotUseLazyLoading, language, icon, searchHandle, events } = props;
	const classes = useStyles();

	const { data, loading: dataLoading, error: dataError } = dataList || {};
	const dataReady = isObject(data) && !dataLoading && !dataError;
	const loading = isObject(data) ? dataLoading : !dataReady;

	const valueOnDebounce = useDebounce(value, 500);

	const [shouldLazyLoadFetch, setShouldLazyLoadFetch] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [fetchOnFocus, setFetchOnFocus] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(10);

	const refStartOfList = useRef();
	const refEndOfList = useRef();

	const popperRef = useRef(null);

	const page = { number: pageNumber, size: pageSize };

	const filters = {
		...(filter && !isNull(filter.value) && !isUndefined(filter.value) && { [filter.name]: filter.value }),
		...(extraFilter && !isNull(extraFilter.value) && !isUndefined(extraFilter.value) && { [extraFilter.name]: extraFilter.value }),
		...(isFunction(searchHandle) && isFullString(valueOnDebounce) && { searchTerm: valueOnDebounce }),
		...(isFunction(searchHandle) && { sortBy: 'name' }),
		...(isFunction(searchHandle) && { orderDescending: false }),
		...(isObject(events) && !isUndefined(events.filter) && { filters: events.filter })
	};

	useEffect(() => {
		if (popperRef.current) {
			popperRef.current.update();
		}
	}, []);

	const inputRef = useRef(null);

	const [openResultsPopper, setOpenResultsPopper] = useState(false);

	const [shouldDoInitialFetchOnFocus, setShouldDoInitialFetchOnFocus] = useState(true);

	useEffect(() => {
		if (shouldDoInitialFetchOnFocus) {
			setShouldSearch(true);
		}
	}, [shouldDoInitialFetchOnFocus]);

	useEffect(() => {
		setPageNumber(1);
		if (!isUndefined(filter)) {
			setShouldFilter(true);
		}
	}, [filter, extraFilter]);

	/* * * * * * * *
	 * FETCH DATA  *
	 * * * * * * * */
	useEffect(() => {
		if (fetchOnFocus && (!dataLoading && (shouldSearch || shouldLazyLoadFetch || shouldFilter))) {
			if (!isUndefined(fetchById)) {
				onFetchData(fetchById, page, filters, true);
			} else {
				onFetchData(page, filters, true);
			}
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldLazyLoadFetch) {
			setShouldLazyLoadFetch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		}
	}, [shouldSearch, shouldLazyLoadFetch, filter, extraFilter, shouldFilter]);

	/* * * * * *
	 * SELECT  *
	 * * * * * */
	const handleSelect = item => {
		setSelected(item);
		setOpenResultsPopper(false);
	};

	/* * * * * * * * *
	 * AUTOCOMPLETE  *
	 * * * * * * * * */
	const [activeIndex, setActiveIndex] = useState(null);
	/* eslint-disable indent */
	const handleOnKeyUp = e => {
		if (!isObject(data) || !data.total) {
			return;
		}

		switch (e.key) {
			case 'Enter':
				if (!isNull(activeIndex)) {
					handleSelect(data.results[activeIndex]);
				}
				break;
			case 'ArrowUp':
				if (activeIndex === 0) {
					setActiveIndex(data.results.length - 1);
				} else if (isNull(activeIndex)) {
					setActiveIndex(0);
				} else {
					setActiveIndex(activeIndex - 1);
				}
				break;
			case 'ArrowDown':
				if (activeIndex === (data.results.length - 1)) {
					setActiveIndex(0);
				} else if (isNull(activeIndex)) {
					setActiveIndex(0);
				} else {
					setActiveIndex(activeIndex + 1);
				}
				break;
			default:
				return;
		}
	};

	/* * * * * * * * * * * * * *
	 * LAZY LOADING LISTITEMS  *
	 * * * * * * * * * * * * * */
	const handleLazyLoading = () => {
		setPageNumber(p => p + 1);
		setShouldLazyLoadFetch(true);
	};

	const lazyLoadMore = useCallback(() => {
		if (refEndOfList && refEndOfList.current && isObject(data)) {
			const bcrStart = refStartOfList.current.getBoundingClientRect();
			const bcrEnd = refEndOfList.current.getBoundingClientRect();

			if (((bcrEnd.top + bcrEnd.height - 250) < (bcrStart.top + bcrStart.height)) && !loading) {
				handleLazyLoading();
			}
		}
	}, [data, loading]);

	useEffect(() => {
		if (!shouldNotUseLazyLoading && isObject(data) && data.hasMore && document.querySelector(`.${classes.selectResults}`)) {
			document.querySelector(`.${classes.selectResults}`).addEventListener('scroll', lazyLoadMore);
		}

		return () => {
			if (document.querySelector(`.${classes.selectResults}`)) {
				document.querySelector(`.${classes.selectResults}`).removeEventListener('scroll', lazyLoadMore);
			}
		};
	}, [lazyLoadMore, classes.selectResults, data, loading, openResultsPopper, shouldNotUseLazyLoading]);

	const loadingContent = (
		<>
			<ListItem button className={classes.listItem}>
				<LoadingBar />
			</ListItem>
			<ListItem button className={classes.listItem}>
				<LoadingBar />
			</ListItem>
			<ListItem button className={classes.listItem}>
				<LoadingBar />
			</ListItem>
		</>
	);

	useEffect(() => {
		if (valueOnDebounce === value) {
			setShouldSearch(true);
			setShouldDoInitialFetchOnFocus(false);
		}
	}, [valueOnDebounce]);

	const handleChange = event => {
		if (isFunction(searchHandle)) {
			searchHandle(event.target.value);
			setPageNumber(1);
		}
	};

	const iconsList = {
		pending: <ScheduleIcon className={classes.scheduleIcon} />,
		verified: <CheckCircleIcon className={classes.checkIconStyle} fontSize='small' />,
		rejected: <CancelRoundedIcon className={classes.iconStyle} />,
		expired: <CancelRoundedIcon className={classes.iconStyle} />,
		notApplicable: <ErrorIcon className={classes.errorStyle} />,
	};

	return (
		<ClickAwayListener onClickAway={() => setOpenResultsPopper(false)}>
			<div className={className}>
				<TextField
					InputProps={{
						endAdornment: openResultsPopper ? <ArrowDropUpIcon className={classes.endAdornment} /> : <ArrowDropDownIcon className={classes.endAdornment} />,
						classes: { root: classes.inputContainer, input: classes.input }
					}}
					fullWidth
					inputProps={{ readOnly: false }}
					inputRef={inputRef}
					onBlur={() => setFetchOnFocus(false)}
					onChange={handleChange}
					onClick={() => {
						if (!data && !openResultsPopper) {
							setShouldSearch(true);
							setShouldDoInitialFetchOnFocus(false);
						}
						if (openResultsPopper) {
							inputRef.current.blur();
						}
						setOpenResultsPopper(!openResultsPopper);
					}}
					onFocus={() => {
						setFetchOnFocus(true);
						setShouldLazyLoadFetch(true);
					}}
					onKeyUp={handleOnKeyUp}
					placeholder={placeholder ? placeholder : ''}
					value={value ? value : ''}
					variant='outlined'
				/>
				<Popper
					anchorEl={inputRef.current ? inputRef.current : null}
					disablePortal
					modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
					open={openResultsPopper}
					popperRef={popperRef}
					style={{
						width: inputRef.current ? inputRef.current.clientWidth : null,
						zIndex: 2,
					}}
				>
					<Paper className={classes.popper}>
						<span ref={refStartOfList} />
						{(!isObject(data) && loading) ? (
							<List>
								{loadingContent}
							</List>
						) : data.total > 0 || (data.predictions && data.predictions.length > 0) ? (
							<List className={clsx({
								[classes.selectResults]: true,
								[classes.smallPopper]: data.total < 5 || isUndefined(data.total),
								[classes.mediumPopper]: 5 < data.total < 20,
								[classes.largePopper]: data.total > 20
							})}>
								{defaultListItem ? (
									<ListItem
										button
										onClick={() => handleSelect(defaultListItem)}
									>
										<Typography className={classes.defaultText}>{defaultListItem.name}</Typography>
									</ListItem>
								) : null}
								{listType === 'users' ? (
									data.results.map((user, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true
											})}
											key={`user-${index}`}
											onClick={() => handleSelect(user)}
										>
											{user.imagesReference && user.imagesReference[0] ? (
												<Avatar
													alt='User'
													className={classes.avatar}
													src={user.imagesReference[0]}
												/>
											) : (
												<Avatar
													alt='User'
													className={classes.avatar}
												>
													{getInitials(`${user.firstName} ${user.lastName}`)}
												</Avatar>
											)}
											<Box>
												<Typography variant='h6'>
													<Box alignItems='center' display='flex'> {user.firstName} {user.lastName}
														{icon ? iconsList[user.licenseStatus] : null}
													</Box>
												</Typography>
												<Typography variant='body2'>
													{user.emailAddress}
												</Typography>
											</Box>
										</ListItem>
									))
								) : listType === 'locations' ? (
									data.predictions.map((item, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true
											})}
											key={`location-${index}`}
											onClick={() => handleSelect(item)}
										>
											<Box>
												<Typography>
													{item.description}
												</Typography>
											</Box>
										</ListItem>
									))
								) : listType === 'items' ? (
									data.results.map((item, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true
											})}
											key={`item-${index}`}
											onClick={() => handleSelect(item)}
										>
											<Box>
												<Typography variant='h6'>
													{item.name}
												</Typography>
												<Typography variant='body2'>
													{item.hubReference.name}
												</Typography>
											</Box>
										</ListItem>
									))
								) : listType === 'planboard-items' ? (
									data.results.map((item, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true
											})}
											key={`item-${index}`}
											onClick={() => handleSelect(item)}
										>
											<Box>
												<Typography variant='h6'>
													{item.title}
												</Typography>
												<Typography variant='body2'>
													{item.hubReference.address.street} {item.hubReference.address.number}, {item.hubReference.address.city}
												</Typography>
											</Box>
										</ListItem>
									))
								) : listType === 'terms' ? (
									data.results.map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true
											})}
											key={`organisation-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>
													{variant.title}
												</Typography>
											</Box>
										</ListItem>
									))
								) : (
									data.results.map((variant, index) => (
										<ListItem
											button
											className={clsx({
												[classes.active]: index === activeIndex,
												[classes.listItem]: true
											})}
											key={`organisation-${index}`}
											onClick={() => handleSelect(variant)}
										>
											<Box>
												<Typography variant='h6'>
													{variant.name}
												</Typography>
											</Box>
										</ListItem>
									))
								)}
								{loading ? (
									loadingContent
								) : null}
								<span ref={refEndOfList} />
							</List>
						) : (
							<div className={classes.empty}>{emptyStateText ? emptyStateText : translate('ui.noResultsFound', language)}</div>
						)}
					</Paper>
				</Popper>
			</div>
		</ClickAwayListener>
	);
};

SelectWithLazyLoading.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	emptyStateText: PropTypes.string,
	dataList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	listType: PropTypes.oneOf(['users', 'items', 'organisations', 'userGroups', 'locations', 'planboard-items', 'terms', 'hubs']),
	defaultListItem: PropTypes.object,
	onFetchData: PropTypes.func.isRequired,
	setSelected: PropTypes.func.isRequired,
	filter: PropTypes.object,
	extraFilter: PropTypes.object,
	fetchById: PropTypes.number,
	shouldNotUseLazyLoading: PropTypes.bool,
	language: PropTypes.string,
	icon: PropTypes.bool,
	searchHandle: PropTypes.func,
	events: PropTypes.object
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language,
	};
};

export default memo(connect(mapStateToProps)(SelectWithLazyLoading), propsAreEqual);
