import { withStyles } from '@mui/styles';
import { Switch } from '@mui/material';
import palette from '../../../theme/palette';

const SwitchButton = withStyles({
	switchBase: {
		color: '#e4e7eb',
		'&$checked': {
			color: palette.primary.main,
		},
		'&$checked + $track': {
			backgroundColor: '#586b85',
		},
	},
	checked: {},
	track: {},

})(Switch);

export default SwitchButton;