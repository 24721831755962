import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isFullString, translate } from '../../../shared/utility';

import {
	Box,
	Dialog,
	DialogContent,
	Typography,
	CircularProgress
} from '@mui/material';
import { StyledButton } from '../../UI';
import { useStyles } from './style';

const CustomDialog = props => {
	const {
		open, handleClose, title, handleConfirm, language, disabled, loading, subTitle, isLoading, children
	} = props;


	const classes = useStyles();

	const circularProgress = loading ? <CircularProgress className={classes.confirmLoading} disableShrink size={24} /> : translate('ui.button.contained.confirm', language);

	return (
		<div className={classes.root}>

			<Dialog
				PaperProps={{ elevation: 1 }}
				classes={{ paper: classes.root }}
				maxWidth='sm'
				onClose={handleClose}
				open={open}
			>
				<Box display='flex' flexDirection='column' pb={2} pl={3} pr={3} pt={2}>
					<Typography variant='h3'>{title}</Typography>
					{isFullString(subTitle) ?
						<Box pt={0.5}>
							<Typography variant='h3'>
								{subTitle}
							</Typography>
						</Box>
						: null
					}
				</Box>
				<DialogContent className={classes.dialog}>
					{children}
				</DialogContent>
				<Box className={classes.actions}>
					<StyledButton
						className={classes.closeButton}
						fullWidth
						onClick={handleClose}
						variant='contained-tertiary'
					>
						{translate('ui.button.contained.cancel', language)}
					</StyledButton>
					<StyledButton
						autoFocus
						className={classes.button}
						disabled={disabled}
						fullWidth
						onClick={handleConfirm}
						variant='contained-primary'
					>
						{isLoading ? circularProgress : translate('ui.button.contained.confirm', language)}
					</StyledButton>
				</Box>
			</Dialog>
		</div>
	);
};

CustomDialog.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string,
	handleConfirm: PropTypes.func,
	language: PropTypes.string,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	isText: PropTypes.bool,
	content: PropTypes.object,
	subTitle: PropTypes.string,
	isLoading: PropTypes.bool,
	children: PropTypes.any
};

const mapStateToProps = state => {
	return {
		language: state.global.language
	};
};

export default withRouter(connect(mapStateToProps)(CustomDialog));