import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { translate } from '../../../shared/utility';

import { StyledButton } from '../../../components';
import { Input } from '../..';

import SearchIcon from '@mui/icons-material/Search';

import { useStyles } from './style';

const Search = props => {
	const { placeholder, events, value, hasSearchButton, hasInlineSearchButton, inputType, className, language } = props;

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)} >
			<Input
				className={classes.searchInput}
				events={events}
				hasClearButton={true}
				hasInlineSearchButton={hasInlineSearchButton}
				icon={!inputType ? <SearchIcon /> : null}
				inputType={inputType ? inputType : null}
				placeholder={placeholder ? placeholder : translate('ui.search', language)}
				value={value}
			/>
			{hasSearchButton ? (
				<StyledButton
					className={classes.searchButton}
					onClick={events.onClick}
					size="large"
					variant="contained-tertiary"
				>
					{translate('ui.button.contained.search', language)}
				</StyledButton>
			) : null}
		</div>
	);
};

Search.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	events: PropTypes.object,
	value: PropTypes.string,
	hasSearchButton: PropTypes.bool,
	hasInlineSearchButton: PropTypes.bool,
	inputType: PropTypes.oneOf(['search-suggest']),
	language: PropTypes.string
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

export default connect(mapStateToProps)(Search);
