import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { translate } from '../../../shared/utility';

import { Drawer, Grid, Typography, Hidden } from '@mui/material';
import { StyledButton } from '../../UI';

import { useStyles } from './style';

const TableEditBar = props => {
	const {
		className,
		actionButtons,
		selected,
		language
	} = props;

	const classes = useStyles();
	const open = selected.length > 0;

	return (
		<Drawer
			anchor="bottom"
			open={open}
			// eslint-disable-next-line react/jsx-sort-props
			PaperProps={{ elevation: 1 }}
			variant="persistent"
		>
			<div
				className={clsx(classes.root, className)}
			>
				<Grid
					alignItems="center"
					container
					spacing={2}
				>
					<Hidden smDown>
						<Grid
							item
							md={3}
						>
							<Typography
								color="textSecondary"
								variant="subtitle1"
							>
								{selected.length} {translate('ui.selected', language)}
							</Typography>
						</Grid>
					</Hidden>
					<Grid
						item
						md={6}
						xs={12}
					>
						<div className={classes.actions}>
							{actionButtons ? actionButtons.map((button, index) => (
								<StyledButton key={`actionButton-${index}`} onClick={() => button.action(selected)} {...(button.variant && { variant: button.variant })}>
									{button.icon ? button.icon : null}
									{button.text}
								</StyledButton>
							)) : null}
							{/* <Button onClick={onMarkPaid}>
                <CheckIcon className={classes.buttonIcon} />
                {translate('ui.markPaid', language)}
              </Button>
              <Button onClick={onMarkUnpaid}>
                <CloseIcon className={classes.buttonIcon} />
                {translate('ui.markUnpaid', language)}
              </Button>
              <Button onClick={onDelete}>
                <DeleteIcon className={classes.buttonIcon} />
                {translate('ui.delete', language)}
              </Button> */}
						</div>
					</Grid>
				</Grid>
			</div>
		</Drawer>
	);
};

TableEditBar.propTypes = {
	className: PropTypes.string,
	actionButtons: PropTypes.array,
	selected: PropTypes.array.isRequired,
	language: PropTypes.string
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

export default connect(mapStateToProps)(TableEditBar);
