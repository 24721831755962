import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		height: '100%'
	},
	navList: {
		display: 'flex',
		padding: 0,
		height: '100%'
	}
}));