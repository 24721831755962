import { listStates } from '../states';
import { FETCH_LIST_START, FETCH_LIST_SUCCESS, FETCH_LIST_FAIL } from '../actions/actionTypes';
import { isArray } from '../../shared/utility';

const initialState = listStates.reduce((state, stateName) => ({
	...state,
	[stateName]: { data: null, loading: false, error: null }
}), {});

/* * * * * * * * * * * * * * * * * * * * * * * * *
* METHODS THAT UPDATE STATES WITH CORRECT VALUES *
* * * * * * * * * * * * * * * * * * * * * * * *  */
const fetchListStart = (state, action) => ({
	...state,
	...{ [action.identifier]: { ...state[action.identifier], ...{ loading: true } } }
});

const fetchListSuccess = (state, action) => ({
	...state,
	...{
		[action.identifier]: {
			...state[action.identifier],
			...{
				data: !isArray(action.data) ? null : action.concat ? state[action.identifier].data.concat(action.data) : action.data.slice(),
				loading: false,
				error: null
			}
		}
	}
});

const fetchListFail = (state, action) => ({
	...state,
	...{ [action.identifier]: { ...state[action.identifier], ...{ data: null, success: false, loading: false, error: action.error } } }
});

/* * * * * * * * *
* REDUCER METHOD *
* * * * * * * *  */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LIST_START: return fetchListStart(state, action);
		case FETCH_LIST_SUCCESS: return fetchListSuccess(state, action);
		case FETCH_LIST_FAIL: return fetchListFail(state, action);
		default: return state;
	}
};

export default reducer;