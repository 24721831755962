import { Suspense, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { LinearProgress } from '@mui/material';
import { isObject } from '../../shared/utility';
import Topbar from './Topbar';

import { useStyles } from './style';

const Dashboard = props => {
	const { route, onSetLocalization, currentUser } = props;

	const { data: currentUserData } = currentUser;

	const classes = useStyles();

	useEffect(() => {
		if (isObject(currentUserData)) {
			onSetLocalization(currentUserData.preferences.preferredCultureInfo);
		}
	}, [currentUser]);

	return (
		<>
			<Topbar className={classes.topBar} />
			<div className={classes.container}>
				<main className={classes.content}>
					<Suspense fallback={<LinearProgress />}>
						{renderRoutes(route.routes)}
					</Suspense>
				</main>
			</div>
		</>
	);
};

Dashboard.propTypes = {
	route: PropTypes.object,
	onSetLocalization: PropTypes.func,
	currentUser: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
};

const mapStateToProps = state => {
	return {
		currentUser: state.details.currentUser,
		// GLOBAL PROPS
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSetLocalization: language => dispatch(actions.setLocalization(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);