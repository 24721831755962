import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import userManager from '../../../../authentication/userManager';
import clsx from 'clsx';
import * as actions from '../../../../store/actions';
import { isObject, getInitials, translate } from '../../../../shared/utility';

import { Avatar, Box, Divider, IconButton, Popover, Typography, Paper } from '@mui/material';
import { LoadingBar, StyledButton } from '../../../../components';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import InputIcon from '@mui/icons-material/Input';
import UserInvitation from './UserInvitation';
import { useStyles } from './style';

const UserMenu = props => {
	const { className, language, currentUser, onFetchCurrentUser } = props;
	const classes = useStyles();

	const { data: userData, loading: userLoading, error: userError } = currentUser;
	const userReady = isObject(userData) && !userLoading && !userError;

	useEffect(() => {
		onFetchCurrentUser();
	}, [onFetchCurrentUser]);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	// const arrowDirection = 'top';

	return (
		<div className={className}>
			<IconButton
				aria-describedby={id}
				onClick={handleClick}
			>
				<AccountCircleOutlinedIcon size='large' />
			</IconButton>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				classes={{ paper: classes.userMenu }}
				id={id}
				onClose={handleClose}
				open={open}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<Box className={classes.userContainer}>
					{userReady ? userData.imagesReference && userData.imagesReference[0] ? (
						<Avatar className={classes.avatar} src={userData.imagesReference[0]} />
					) : (
						<Avatar className={classes.avatar}>{getInitials(`${userData.name}`)}</Avatar>
					) : (
						<Avatar className={classes.avatar}>-</Avatar>
					)}
					{userReady ? (
						<Box alignItems='center' display='flex' flexDirection='column'>
							<Typography className={classes.userDetailText} variant='h4'>{userData.name}</Typography>
							<Typography className={classes.userDetailText} variant='body2'>{userData.emailAddress}</Typography>
							<div>
								<Paper
									className={clsx({
										[classes.tipCard]: true,
									})}
								>
									<Typography variant='body2'>
										{translate('views.layouts.editing', language)}
									</Typography>

								</Paper>
							</div>

						</Box>
					) : (
						<Box alignItems='center' display='flex' flexDirection='column'>
							<LoadingBar className={classes.userDetailText} />
							<LoadingBar className={classes.userDetailText} />
						</Box>
					)}
				</Box>
				<Divider />
				<Box className={classes.buttonContainer}>
					<StyledButton
						className={classes.logoutButton}
						onClick={() => userManager.signoutRedirect()}
						startIcon={<InputIcon />}
						variant='inline-default'
					>
						{translate('ui.button.inline.signout', language)}
					</StyledButton>
				</Box>
			</Popover>
			<UserInvitation />
		</div>
	);
};

UserMenu.propTypes = {
	currentUser: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
	language: PropTypes.string,
	className: PropTypes.string,
	onFetchCurrentUser: PropTypes.func
};

const mapStateToProps = state => {
	return {
		currentUser: state.details.currentUser,
		// GLOBAL PROPS
		language: state.global.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onFetchCurrentUser: () => dispatch(actions.fetchCurrentUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);