import palette from '../palette';

export default {
	styleOverrides: {
		select: {
			'&:focus': {
				backgroundColor: palette.background.default
			}
		}
	}
};