import PropTypes from 'prop-types';
import clsx from 'clsx';

import { StyledButton } from '../../../../components';

import { useStyles } from './style';

const ExtraButtons = props => {
	const { buttons } = props;

	const classes = useStyles();

	return (
		<div
			className={clsx(classes.root)}
		>
			{buttons.map((button, index) => (
				<StyledButton className={classes.extraButton} key={index} size='medium' {...(button.variant && { variant: button.variant })} disabled={button.disabled} onClick={button.onClick}>{button.text}</StyledButton>
			))}
		</div>
	);
};

ExtraButtons.propTypes = {
	buttons: PropTypes.arrayOf(Object).isRequired //adjust to proper proptype when this is clear
};

export default ExtraButtons;