export const blobStates = [];
export const conditionStates = ['canceledBooking', 'updatedBookingDetails', 'patchTrip'];

export const detailsStates = [
	'addedPlanboardBooking', 'bookingAvailability', 'bookingPrice', 'calculatedPrice', 'itemInstanceAvailability',
	'itemPrice', 'paymentMethodsUser', 'planboardItemDetails', 'user', 'planboardBooking', 'startBooking', 'stopBooking', 'validatedBookingCategory',
	'validatedEditedBookingCategory', 'currentUser', 'addedTicket', 'fetchInstance', 'planboardItemDetails', 'fetchBooking',
	'trip', 'bookingTrip', 'userInvitation', 'updateInvite', 'exportBookingTrip', 'exportTrips', 'exportTripDetails', 'exportBookingTripDetails'
];

export const listStates = ['categories', 'ticketDefects', 'tripCoordinates', 'bookingTripCoordinates', 'searchInstance'];

export const pagedStates = ['hubs', 'itemInstances', 'items', 'organisations', 'users', 'bookings', 'bookingTrips', 'trips', 'hubsSlim', 'organisationsSlim'];

export const planboardStates = ['planboardBookings', 'planboardItemsToAdd'];
