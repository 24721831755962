import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { navigationConfig } from './navigationConfig';

import { Navigation } from '../../../../components';

import { useStyles } from './style';

const ViewSwitch = props => {
	const { language, className } = props;
	const classes = useStyles();

	return (
		<nav className={clsx(classes.root, className)}>
			{navigationConfig(language).map(list => (
				<Navigation
					component="div"
					key={list.title}
					pages={list.pages}
				/>
			))}
		</nav>
	);
};

ViewSwitch.propTypes = {
	language: PropTypes.string,
	className: PropTypes.string
};

const mapStateToProps = state => {
	return {
		language: state.global.language
	};
};

export default connect(mapStateToProps, null)(ViewSwitch);