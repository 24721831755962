import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4),
		maxWidth: 550,
		overflow: 'visible'
	},
	dialogTitle: {
		fontFamily: 'Poppins, sans-serif !important',
		padding: 0,
		fontWeight: 500,
		fontSize: '20px !important',
		letterSpacing: '-0.06px !important',
		lineHeight: '24px !important'
	},
	dialogContent: {
		...theme.typography.body2,
		padding: '40px 0 !important',
		lineHeight: '22px'
	},
	dialogActions: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: theme.spacing(4),
		marginTop: theme.spacing(2)
	},
	button: {
		height: 40,
		minWidth: '220px !important'
	}
}));
