import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function CustomNumberFormat(props) {
	const { inputRef, onChange, currencySymbol, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			isNumericString
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			prefix={currencySymbol}
			thousandSeparator
		/>
	);
}

CustomNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	currencySymbol: PropTypes.string
};

export default CustomNumberFormat;