import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
	IconButton,
	Typography,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(() => ({
	root: {

		// alignItems: 'center',
		// margin: 0,
		// // padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		paddingBottom: '14px',
		paddingTop: '14px'
	},
	closeButton: {
		padding: 0,
	},
}));

const DialogTitleCustom = props => {

	const { onClose, title, icon } = props;

	const classes = useStyles();

	return (
		<MuiDialogTitle className={classes.root} disableTypography>
			<Typography variant="h6">{title}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					{icon}
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

DialogTitleCustom.propTypes = {
	onClose: PropTypes.func,
	title: PropTypes.string,
	icon: PropTypes.object
};

export default DialogTitleCustom;