import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import userManager from './authentication/userManager';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import reducer from './store/reducers/index';
import { createBrowserHistory } from 'history';

import App from './App';
import theme from './theme';

const container = document.getElementById('root');
const root = createRoot(container);

const enableReduxLogger = false;
const middleware = [...getDefaultMiddleware({
	// serializableCheck: false, // disable all serializable check
	serializableCheck: {
		ignoredActions: ['redux-oidc/USER_FOUND', 'store/actions/actionTypes/FETCH_BLOB_SUCCESS'],
		ignoredActionPaths: ['data'],
		ignoredPaths: ['oidc.user', 'blob.csvBillingReport']
	}
}), ...(enableReduxLogger ? [logger] : [])];

export const store = configureStore({
	reducer,
	middleware,
	devTools: process.env.NODE_ENV !== 'production',
});

const history = createBrowserHistory();
loadUser(store, userManager);

const app = (
	<Provider store={store}>
		<OidcProvider store={store} userManager={userManager}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Router history={history}>
							<App />
						</Router>
					</LocalizationProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</OidcProvider>
	</Provider>
);

root.render(app);
