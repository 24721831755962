import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	filterContainer: {
		width: '100%',
		height: '44px'
	},
	filterInput: {
		width: '100%',
		minWidth: 180,
		maxWidth: 280,
		padding: '8px'
	},
	searchAutocomplete: {
		height: '44px',
		width: '100%',
		minWidth: 180,
		maxWidth: 280,
		'& div div .MuiOutlinedInput-input': {
			padding: '13.5px 14px'
		}
	}
}));