import { useEffect, useRef, useState, useContext } from 'react';
import { __RouterContext } from 'react-router';
import { useSnackbar } from 'notistack';
import { isEmptyString, isEmptyArray, isObject, isFullArray, isUndefined, isNull, isFullString } from './utility';

import { validateInput } from './validationWithCustomMessages';

function usePrevious(value) {
	const ref = useRef(value);
	useEffect(() => { ref.current = value; });
	return ref.current;
}

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
}

function useRouter() {
	return useContext(__RouterContext);
}

function useWizardFormField(initialValue = '', initialValidationOptions = {}) {
	const [value, setValue] = useState(initialValue);
	const [isRequired, setIsRequired] = useState(!!initialValidationOptions.required);
	const [validationOptions, setValidationOptions] = useState(initialValidationOptions);
	const [isValid, setIsValid] = useState(false);
	const [validationError, setValidationError] = useState({});
	const [isTouched, setIsTouched] = useState(false);
	const [hasFocus, setHasFocus] = useState(false);

	const onBlur = e => {
		const onBlurValue = e.target.value.trim();
		const validatedInput = validateInput(onBlurValue, validationOptions);

		setIsTouched(true);
		setIsValid(validatedInput.valid);
		setValidationError(validatedInput.error);
		setValue(onBlurValue);
		setHasFocus(false);
	};

	const onChange = e => {
		const onChangeValue = e.target ? e.target.value : e;
		setIsTouched(true);
		setValue(onChangeValue);
	};

	const onFocus = () => {
		setHasFocus(true);
	};

	const setTouched = touched => {
		setIsTouched(touched);
	};

	const setRequired = required => {
		setValidationOptions({
			...validationOptions,
			required
		});
		setIsRequired(required);
	};

	const setNewValidationOptions = options => {
		setValidationOptions(options);
	};

	const resetToInitialValue = () => {
		setValue(initialValue);
		setIsRequired(!!initialValidationOptions.required);
		setValidationOptions(initialValidationOptions);
		setIsValid(false);
		setValidationError({});
		setIsTouched(false);
		setHasFocus(false);
	};

	//check if value is valid when validationOptions change
	useEffect(() => {
		if (value || value === '') {
			const validatedInput = validateInput(value, validationOptions);
			setIsValid(validatedInput.valid);
			setValidationError(validatedInput.error);
		}
	}, [validationOptions, value]);

	return {
		value,
		isRequired,
		isValid,
		validationError,
		isTouched,
		hasFocus,
		onChange,
		setTouched,
		setRequired,
		validationOptions,
		setNewValidationOptions,
		resetToInitialValue,
		setValue,
		bindToFormField: {
			value,
			onChange,
			onBlur,
			onFocus
		}
	};
}

function useDebouncedWizardSave(name, value, valid, save, delay = 300, required) {
	const debouncedValue = useDebounce(value, delay);

	useEffect(() => {
		save({
			[name]: {
				value,
				valid,
				required
			}
		});
	}, [debouncedValue, required, valid]);
}

function useDebouncedBackendFieldCheck(value, check, checkedField, otherVariables = {}, shouldCheckResponseBodyForSuccess = false, delay = 300) {
	const debouncedValue = useDebounce(value, delay);
	const [isUnique, setIsUnique] = useState(true);
	const [checkIsSuccessful, setCheckIsSuccessful] = useState(false);

	useEffect(() => {
		if (!isEmptyString(debouncedValue)) {
			check({ ...otherVariables, value: debouncedValue });
		}
	}, [debouncedValue]);

	const { [shouldCheckResponseBodyForSuccess ? 'data' : 'success']: checkedFieldSuccess, loading: checkedFieldLoading, error: checkedFieldError } = checkedField;

	useEffect(() => {
		if (shouldCheckResponseBodyForSuccess) {
			if (isObject(checkedFieldSuccess) && !checkedFieldLoading && !checkedFieldError) {
				setIsUnique(checkedFieldSuccess.allowed);
			} else {
				setIsUnique(false);
			}
		} else {
			if (!checkedFieldLoading && !isObject(checkedFieldError)) {
				setIsUnique(true);
			} else if (!shouldCheckResponseBodyForSuccess && !checkedFieldLoading) {
				setIsUnique(false);
			}
		}

		if ((checkedFieldSuccess || isObject(checkedFieldSuccess)) && !checkedFieldLoading && !checkedFieldError) {
			setCheckIsSuccessful(true);
		} else if (!checkedFieldLoading && checkedFieldError) {
			setCheckIsSuccessful(false);
		}
	}, [checkedFieldSuccess, checkedFieldLoading, checkedFieldError, shouldCheckResponseBodyForSuccess]);

	return {
		isUnique,
		checkIsSuccessful
	};
}

function useDebouncedLocalFieldCheck(value, fieldName, check, delay = 300) {
	const debouncedValue = useDebounce(value, delay);
	const [isUnique, setIsUnique] = useState(true);

	useEffect(() => {
		if (!isEmptyString(debouncedValue)) {
			setIsUnique(check(fieldName, debouncedValue));
		}
	}, [debouncedValue]);

	return isUnique;
}

function useEventListener(eventName, handler, element = window) {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;

		const eventListener = event => savedHandler.current(event);

		element.addEventListener(eventName, eventListener);

		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
}

function useSelectData(selected, setSelected, dataSelected, setDataSelected, data) {

	useEffect(() => {
		if (!isEmptyArray(dataSelected)) {
			const newSelected = data.reduce((acc, item, index) => ([
				...acc,
				...(!isUndefined(dataSelected.find(selectedItem => !isUndefined(selectedItem.innerId) ? (selectedItem.innerId === item.innerId) : (selectedItem.id === item.id))) ? [index] : [])
			]), []);

			setSelected(newSelected);
		}
	}, [data]);

	const handleSelectOne = (event, index) => {
		if (isFullArray(data)) {
			const selectedIndex = selected.indexOf(index);
			const dataSelectedIndex = dataSelected.map(item => item.id).indexOf(data[index].id);

			const newSelected = (selectedIndex === -1) ? [...selected, index] : [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)];
			const newDataSelected = (selectedIndex === -1) ? [...dataSelected, data[index]] : [...dataSelected.slice(0, dataSelectedIndex), ...dataSelected.slice(dataSelectedIndex + 1)];

			setSelected(newSelected);
			setDataSelected(newDataSelected);
		}
	};

	const handleSelectAll = event => {

		setSelected(event.target.checked ? data.map((_, index) => index) : []);
		setDataSelected(event.target.checked ? data : []);
	};
	return {
		handleSelectOne,
		handleSelectAll
	};
}

function useError(value) {

	const { enqueueSnackbar } = useSnackbar();

	const [startAction, setStartAction] = useState(false);

	const { loading, error } = value.value;
	const actionDone = (isObject(value.value.data) || isFullArray(value.value.data) || value.value.success) && !loading && !error;

	const detailedError = isObject(error) && isObject(error.additionalData) && isFullArray(error.additionalData.detailedErrorMessages) && error.additionalData.detailedErrorMessages;

	useEffect(() => {
		if (startAction && actionDone) {
			enqueueSnackbar(`${value.message}`, isFullString(value.variant) ? value.variant === 'none' ? {} : { variant: value.variant } : { variant: 'success' });
			setStartAction(false);
		}
	}, [startAction, actionDone]);

	useEffect(() => {
		if (isObject(error) && startAction && !loading && !isObject(error.additionalData)) {
			enqueueSnackbar(error.message, { variant: 'error' });
			setStartAction(false);
		} else if (isFullString(error) && startAction) {
			enqueueSnackbar(error, { variant: 'error' });
			setStartAction(false);
		} else if (startAction && isObject(error) && isFullArray(detailedError) && !loading) {
			enqueueSnackbar(isFullArray(detailedError) ? `${detailedError.map(detailedMessage => detailedMessage.value).join('. ')}.` : error, { variant: 'error' });
			setStartAction(false);
		}
	}, [startAction, error]);

	return {
		startAction,
		setStartAction
	};
}

function useCloseTimeout(data) {

	const [action, setAction] = useState(false);
	const [value, setValue] = useState(data);
	const { actionDone, loading, error } = value;

	const prevLoading = usePrevious(value.loading);
	useEffect(() => {
		if (loading !== data.loading) {
			setValue(data);
		}
	}, [data]);

	useEffect(() => {
		if (loading !== prevLoading) {
			if (prevLoading && !loading && !actionDone) {
				const errorTimer = setTimeout(() => setAction(false), value.delay ? value.delay : 30000);
				if (!isNull(error) || actionDone) {
					setAction(false);
					return () => clearTimeout(errorTimer);
				}
			}
			setAction(!value.actionDone);
		}
	}, [loading]);

	return {
		action,
		setAction
	};
}

function useItemInstanceSelection(value = []) {
	const [selection, setSelection] = useState(value);

	const [shouldRemoveItem, setShouldRemoveItem] = useState(false);
	const [shouldRemoveInstance, setShouldRemoveInstance] = useState(false);
	const [removeId, setRemoveId] = useState();
	const [removeInstanceId, setRemoveInstanceId] = useState();

	const removeItem = (item = null, instance = null) => {
		const selectedItemIds = selection.map(selectedItem => selectedItem.item.id);
		const itemIndex = selectedItemIds.indexOf(item.id);
		const selectedItemInstanceIds = selection[itemIndex].selectedInstances ? selection[itemIndex].selectedInstances.map(selectedItemInstance => selectedItemInstance.id) : [];
		if ((!isNull(item) && isNull(instance)) || (!isNull(instance) && selectedItemInstanceIds.length === 1)) {
			//removing whole item from selection
			setSelection(Array(0).concat(
				selection.slice(0, itemIndex),
				selection.slice(itemIndex + 1)
			));
		} else {
			//remove instance
			setSelection(selection.map(selectedItem => ({
				...selectedItem,
				selectedInstances: selectedItem.item.id === item.id ? isEmptyArray(selectedItem.selectedInstances) ? item.instances.filter(newInstance => newInstance.id !== instance.id) : Array(0).concat(
					selectedItem.selectedInstances.slice(0, selectedItemInstanceIds.indexOf(instance.id)),
					selectedItem.selectedInstances.slice(selectedItemInstanceIds.indexOf(instance.id) + 1)
				) : selectedItem.selectedInstances
			})));
		}
	};

	const handleRemoveItemFromSidebar = (item = null, instance = null) => {
		if (!isNull(item) && isNull(instance)) {
			setShouldRemoveItem(true);
			setRemoveId(item.id);
		} else {
			setShouldRemoveInstance(true);
			setRemoveId(item.id);
			setRemoveInstanceId(instance.id);
		}
		removeItem(item, instance);
	};

	const addItem = (item = null, instance = null) => {
		const selectedItemIds = selection.map(item => item.item.id);
		const itemIndex = selectedItemIds.indexOf(item.id);

		if (!isNull(item) && isNull(instance)) {
			// selectAll
			if (itemIndex === -1) {
				setSelection(selection.concat([{
					item,
					selectedInstances: isFullArray(item.instances) ? item.instances : []
				}]));
			} else {
				setSelection(selection.map(selectedItem => ({
					...selectedItem,
					selectedInstances: selectedItem.item.id === item.id ? isFullArray(item.instances) ? item.instances : [] : selectedItem.selectedInstances
				})));
			}
		} else {
			//instance adding
			if (selectedItemIds.indexOf(item.id) === -1) {
				setSelection(selection.concat([{
					item,
					selectedInstances: [instance]
				}]));
			} else {
				setSelection(selection.map(selectedItem => ({
					...selectedItem,
					selectedInstances: selectedItem.item.id === item.id ? selectedItem.selectedInstances.concat([instance]) : selectedItem.selectedInstances
				})));
			}
		}
	};

	return {
		selection,
		setSelection,
		shouldRemoveItem,
		setShouldRemoveItem,
		shouldRemoveInstance,
		setShouldRemoveInstance,
		removeId,
		removeInstanceId,
		handleRemoveItemFromSidebar,
		removeItem,
		addItem
	};
}

function useSearchComponent(setPageNumber, setShouldSearch, shouldSearchOnDebounce = false) {
	const [value, setValue] = useState('');
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const handleSearchClick = () => {
		if (!isEmptyString(value)) {
			setPageNumber(1);
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(value) && isShowingSearchResults) {
			setPageNumber(1);
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = e => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: e => setValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: e => handleKeyUp(e)
	};

	const debouncedSearchValue = useDebounce(value, 500);

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	return {
		value,
		events
	};
}
function usePagination(sessionKeyPageNumber = 'pageNumber', sessionKeyPageSize = 'pageSize', pagination = { number: 1, size: 10 }) {
	const [pageNumber, setPageNumber] = useState(sessionStorage.getItem(sessionKeyPageNumber) ? parseInt(sessionStorage.getItem(sessionKeyPageNumber), 10) : pagination.number);
	const [pageSize, setPageSize] = useState(sessionStorage.getItem(sessionKeyPageSize) ? parseInt(sessionStorage.getItem(sessionKeyPageSize), 10) : pagination.size);
	const [fetch, setFatch] = useState(false);

	const page = { number: pageNumber, size: pageSize };

	if (pagination.number === pageNumber && pagination.size === pageSize) {
		sessionStorage.removeItem(sessionKeyPageNumber);
		sessionStorage.removeItem(sessionKeyPageSize);
	} else {
		sessionStorage.setItem(sessionKeyPageNumber, pageNumber);
		sessionStorage.setItem(sessionKeyPageSize, pageSize);
	}

	const pageNumberChange = page => {
		setPageNumber(page);
		setFatch(true);
	};

	const pageSizeChange = newSize => {
		setPageSize(newSize);
		setPageNumber(1);
		setFatch(true);
	};

	const resetPagination = () => {
		setPageNumber(pagination.number);
		setPageSize(pagination.size);
		setFatch(true);
	};

	return { fetch, setFatch, page, pageNumber, setPageNumber, pageSize, pageNumberChange, pageSizeChange, resetPagination };
}

function useDetailPageBackButton(location) {
	const [isBackButton, setIsBackButton] = useState(false);

	useEffect(() => {
		if (!!location.state && !!location.state.from && !location.state.showBackButton) {
			setIsBackButton(false);
		} else {
			setIsBackButton(true);
		}
	}, [location]);

	return {
		isBackButton
	};
}

function useCreateControl(action, label) {
	const controlButton = document.createElement('button');
	controlButton.style.backgroundColor = '#fff';
	controlButton.style.border = '2px solid #fff';
	controlButton.style.borderRadius = '3px';
	controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
	controlButton.style.color = 'rgb(25,25,25)';
	controlButton.style.cursor = 'pointer';
	controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
	controlButton.style.fontSize = '16px';
	controlButton.style.lineHeight = '38px';
	controlButton.style.margin = '8px 0 22px';
	controlButton.style.padding = '0 5px';
	controlButton.style.textAlign = 'center';
	controlButton.textContent = label;
	controlButton.title = 'Click add data points';
	controlButton.type = 'button';

	controlButton.addEventListener('click', action);
	controlButton.addEventListener('mouseover', () => {
		controlButton.style.backgroundColor = 'rgb(235, 235, 235)';
		controlButton.style.border = '2px solid rgb(235, 235, 235';
	});
	controlButton.addEventListener('mouseout', () => {
		controlButton.style.backgroundColor = '#fff';
		controlButton.style.border = '2px solid #fff';
	});
	return controlButton;
}


export {
	useCreateControl,
	usePrevious, useDebounce, useRouter, useWizardFormField, useDebouncedWizardSave, usePagination,
	useEventListener, useDebouncedBackendFieldCheck, useDebouncedLocalFieldCheck, useSelectData,
	useItemInstanceSelection, useError, useCloseTimeout, useSearchComponent, useDetailPageBackButton
};