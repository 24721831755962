import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { StyledButton } from '../../../components';
import { useStyles } from './style';

const ActionDialog = props => {
	const { open, handleClose, actionButtonProps, title, children, loading, variant, noDivider, decline, declineVarinat } = props;
	const classes = useStyles();

	return (
		<Dialog
			classes={{ paper: classes.root }}
			onClose={handleClose}
			open={open}
		>
			<DialogTitle className={classes.dialogTitle} sx={{ paddingLeft: 0 }}>{title}</DialogTitle>
			<DialogContent className={classes.dialogContent}>{children}</DialogContent>
			{noDivider ? null : <Divider />}
			<DialogActions classes={{ root: classes.dialogActions }}>
				<StyledButton
					className={classes.button}
					disabled={loading}
					onClick={handleClose}
					size='large'
					variant={declineVarinat}
				>
					{decline}
				</StyledButton>
				<StyledButton
					className={classes.button}
					disabled={loading}
					onClick={actionButtonProps.action}
					size='large'
					variant={variant}
				>
					{actionButtonProps.text}
				</StyledButton>
			</DialogActions>
		</Dialog>
	);
};

ActionDialog.defaultProps = {
	variant: 'contained-primary',
	declineVarinat: 'inline-default'
};

ActionDialog.propTypes = {
	declineVarinat: PropTypes.string,
	noDivider: PropTypes.bool,
	loading: PropTypes.bool,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	variant: PropTypes.string,
	actionButtonProps: PropTypes.object,
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	decline: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};



export default ActionDialog;