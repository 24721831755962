import { pagedStates } from '../states';
import { isObject } from '../../shared/utility';
import { FETCH_PAGED_START, FETCH_PAGED_SUCCESS, FETCH_PAGED_FAIL } from '../actions/actionTypes';

const initialState = pagedStates.reduce((state, stateName) => ({
	...state,
	[stateName]: { data: null, loading: false, error: null },
}), {});

/* * * * * * * * * * * * * * * * * * * * * * * * *
* METHODS THAT UPDATE STATES WITH CORRECT VALUES *
* * * * * * * * * * * * * * * * * * * * * * * *  */
const fetchPagedStart = (state, action) => {
	return ({
		...state,
		...{ [action.identifier]: { ...state[action.identifier], ...{ loading: true } } },
	});
};

const fetchPagedSuccess = (state, action) => {
	return ({
		...state,
		...{
			[action.identifier]: {
				...state[action.identifier], ...{
					// data: (isObject(state[action.identifier].data) && isObject(action.data) && state[action.identifier].data.pageNumber < action.data.pageNumber) ? (
					//     { ...action.data, ...{ results: [...state[action.identifier].data.results, ...action.data.results] } }
					// ) : action.data,
					data: !isObject(action.data) ? null : action.concat ? { ...action.data, ...{ results: [...state[action.identifier].data.results, ...action.data.results] } } : { ...action.data },
					loading: false,
					error: null,
				}
			}
		},
	});
};

const fetchPagedFail = (state, action) => ({
	...state,
	...{ [action.identifier]: { ...state[action.identifier], ...{ data: null, success: false, loading: false, error: action.error } } },
});

/* * * * * * * * *
* REDUCER METHOD *
* * * * * * * *  */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PAGED_START: return fetchPagedStart(state, action);
		case FETCH_PAGED_SUCCESS: return fetchPagedSuccess(state, action);
		case FETCH_PAGED_FAIL: return fetchPagedFail(state, action);
		default: return state;
	}
};

export default reducer;