import { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { isObject, isEmptyObject } from '../../../../shared/utility';

import { Box } from '@mui/material';
import { Input } from '../../../../components';

import { useStyles } from './style';
import { SearchAutocomplete, SelectedOrganisationCard, SelectWithLazyLoading } from '../../../../components';

const propsAreEqual = (prevProps, nextProps) => {
	return prevProps.className === nextProps.className && JSON.stringify(prevProps.events) === JSON.stringify(nextProps.events) && prevProps.value === nextProps.value && JSON.stringify(prevProps.selectOptions) === JSON.stringify(nextProps.selectOptions) && JSON.stringify(prevProps.dataList) === JSON.stringify(nextProps.dataList);
};

const MainFilter = memo(props => {
	const { events, value, selectOptions, className, isSearchAutocomplete, isSelectWithLazyLoading, dataList, onFetchData, placeholder, listType, defaultListItem } = props;

	const classes = useStyles();

	return (
		<div
			className={clsx(classes.root, className)}
		>
			<Box alignItems="center" className={classes.filterContainer} display="flex">
				{isSearchAutocomplete ? isObject(value) && !isEmptyObject(value) ? (
					<SelectedOrganisationCard
						className={classes.searchAutocomplete}
						handleClose={() => events.onChange({})}
						name={value.name}
					/>
				) : (
					<SearchAutocomplete
						className={classes.searchAutocomplete}
						dataList={dataList}
						listType={listType}
						onFetchData={onFetchData}
						placeholder={placeholder}
						setSelected={events.onChange}
					/>
				) : isSelectWithLazyLoading ? (
					<SelectWithLazyLoading
						className={classes.searchAutocomplete}
						dataList={dataList}
						defaultListItem={defaultListItem}
						listType={listType}
						onFetchData={onFetchData}
						placeholder={placeholder}
						searchHandle={events.searchHandle}
						setSelected={events.onChange}
						value={value}
					/>
				) : (
					<Input
						className={classes.filterInput}
						events={{ onChange: events.onChange }}
						inputType="select"
						selectOptions={selectOptions}
						value={value}
					/>
				)}
			</Box>
		</div>
	);
}, propsAreEqual);

MainFilter.propTypes = {
	className: PropTypes.string,
	events: PropTypes.object,
	value: PropTypes.any,
	selectOptions: PropTypes.array,
	isSearchAutocomplete: PropTypes.bool,
	isSelectWithLazyLoading: PropTypes.bool,
	dataList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	listType: PropTypes.oneOf(['users', 'items', 'organisations', 'userGroups', 'locations', 'planboard-items', 'terms', 'hubs']),
	defaultListItem: PropTypes.object,
	onFetchData: PropTypes.func,
	placeholder: PropTypes.string,
};

MainFilter.defaultProps = {
	selectOptions: [
		{
			value: 'all'
		},
		{
			value: 'Skopei'
		},
		{
			value: 'Randstad'
		},
		{
			value: 'Skopei'
		}
	]
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

export default connect(mapStateToProps)(MainFilter);