import { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { styles } from './style';
import { withStyles } from '@mui/styles';

const AdjustedSkeleton = forwardRef(function AdjustedSkeleton(props, ref) {
	const {
		animation = 'pulse',
		classes,
		className,
		component: Component = 'span',
		height,
		variant = 'text',
		width,
		...other
	} = props;

	return (
		<Component
			className={clsx(
				classes.root,
				classes[variant],
				{
					[classes[animation]]: animation !== false,
				},
				className,
			)}
			ref={ref}
			{...other}
			style={{
				width,
				height,
				...other.style,
			}}
		/>
	);
});

AdjustedSkeleton.propTypes = {
	animation: PropTypes.oneOf(['pulse', 'wave', false]),
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	component: PropTypes.elementType,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	variant: PropTypes.oneOf(['text', 'rect', 'circle']),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withStyles(styles)(AdjustedSkeleton);