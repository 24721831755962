import { Fragment, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ListItemIcon, ListItemText, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';

import MoreIcon from '@mui/icons-material/MoreVert';

import { useStyles } from './style';

const GenericMoreButton = props => {
	const { className, menuItems } = props;
	const classes = useStyles();

	const moreRef = useRef(null);

	const [openMenu, setOpenMenu] = useState(false);

	const handleMenuOpen = () => {
		setOpenMenu(true);
	};

	const handleMenuClose = () => {
		setOpenMenu(false);
	};

	return (
		<Fragment>
			<Tooltip title="More options">
				<IconButton
					className={className}
					onClick={handleMenuOpen}
					ref={moreRef}
					size="small"
				>
					<MoreIcon />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={moreRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handleMenuClose}
				open={openMenu}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				{menuItems.map((menuItem, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							menuItem.action();
							handleMenuClose();
						}}
					>
						<ListItemIcon className={clsx({
							[classes.red]: menuItem.isRed
						})}>
							{menuItem.icon}
						</ListItemIcon>
						<ListItemText
							primary={menuItem.text}
							primaryTypographyProps={{
								className: clsx({
									[classes.red]: menuItem.isRed
								})
							}}
						/>
					</MenuItem>
				))}
			</Menu>
		</Fragment>
	);
};

GenericMoreButton.propTypes = {
	className: PropTypes.string,
	menuItems: PropTypes.array.isRequired
};

export default memo(GenericMoreButton);
