import { pagedStates } from '../states';
import { FETCH_PAGED_START, FETCH_PAGED_SUCCESS, FETCH_PAGED_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isArray, isFunction, isFullArray, isEmptyArray } from '../../shared/utility';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const PagedStatesCounter = new Counter(pagedStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchPagedStart = identifier => {
	return {
		type: FETCH_PAGED_START,
		identifier: identifier,
	};
};

export const fetchPagedSuccess = (identifier, data = {}, concat = false) => {
	PagedStatesCounter.reset(identifier);
	return {
		type: FETCH_PAGED_SUCCESS,
		identifier: identifier,
		data: data,
		concat: concat,
	};
};

export const fetchPagedFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
	PagedStatesCounter.reset(identifier);
	return {
		type: FETCH_PAGED_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const updatePagedState = (identifier, data = null, concat = false) => {
	return dispatch => {
		dispatch(fetchPagedSuccess(identifier, data, concat));
	};
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
** * * * * * * * * * * * * * * */
const fetchPaged = (identifier, path, settings = {}) => {
	const current = PagedStatesCounter.increment(identifier);
	const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
	return async dispatch => {
		dispatch(fetchPagedStart(identifier));
		try {
			let data = await (
				isArray(path) ?
					Promise.all(path.map(p => events[method](p, bodyData))) :
					events[method](path, bodyData)
			);
			if (PagedStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(transformer)) {
					data = transformer(data);
				}
				dispatch(fetchPagedSuccess(identifier, data, !!concat));
			}
		} catch (error) {
			if (PagedStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(errorCallback)) {
					errorCallback(dispatch, error);
				} else {
					dispatch(fetchPagedFail(identifier, error));
				}
			}
		}
	};
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * * * *
 * UTILITY METHODS *
** * * * * * * * * */

const flattenFilters = filters => {
	const filtersStr = isFullArray(Object.keys(filters)) ? (
		Object.entries(filters).reduce((arr, map) => (
			// flatten filterproperties is passed in array
			isEmptyArray(map[1]) ? (
				arr
			) : isFullArray(map[1]) ? (
				[...arr, ...map[1].map(value => [map[0], value])]
			) : [...arr, ...[map]]
		), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	) : '';

	const filterString = '&' + [filtersStr].filter(str => str.length).join('&');

	return filterString;
};

/* * * * * * * * * *
* BOOKINGS  MODELS *
* * * * * * * * * */
export const fetchBookings = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'bookings',
		`bookings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

/* * * * * * * * * * *
 * LOCATIONS METHODS *
 * * * * * * * * * * */
export const fetchHubs = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'hubs',
		`hubs?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

export const hubsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'hubsSlim',
		`hubs/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

/* * * * * * * * *
 * ITEMS METHODS *
 * * * * * * * * */
export const fetchItems = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'items',
		`items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

/* * * * * * * * *
* ITEM INSTANCES *
* * * * * * * * */
export const fetchItemInstances = (itemId = null, page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'itemInstances',
		`items/${itemId}/iteminstances?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

/* * * * * * * * * * * * *
 * ORGANISATION METHODS  *
 * * * * * * * * * * * * */
export const fetchOrganisations = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'organisations',
		`organisations?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

export const organisationsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'organisationsSlim',
		`organisations/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

/* * * * * * * * *
 * USER METHODS  *
 * * * * * * * * */
export const fetchUsers = (page = { number: 1, size: 10 }, filters = {}, concat = false, includeConsumers = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'users',
		`users?pageNumber=${page.number}&pageSize=${page.size}&includeConsumers=${includeConsumers}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

/* * * * * * * * *
 * Trip METHODS  *
 * * * * * * * * */
export const fetchBookingTrips = (bookingId = null, page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'bookingTrips',
		`bookings/${bookingId}/trips?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

export const trips = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'trips',
		`trips?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};