import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	media: {
		height: 80,
		width: 80,
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		objectFit: 'contain'
	},
	placeholder: {
		objectFit: 'none'
	},
	closeButton: {
		marginLeft: 'auto',
		alignSelf: 'flex-start',
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}));