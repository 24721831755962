import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './style';

const gMap = {
	baseUrl: 'https://maps.googleapis.com/maps/api/staticmap',
	key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
};

const GoogleMap = props => {
	const { lat, lng, className, isImage } = props;
	const classes = useStyles();
	const markerColor = '304ffe';
	return isImage ? (
		<div className={clsx(classes.root, className)}>
			<img
				alt='map'
				className={classes.map}
				src={`${gMap.baseUrl}?center=${lat},${lng}&zoom=15&size=600x259&style=feature:poi%7Cvisibility:off&maptype=roadmap&markers=size:small%7Ccolor:0x${markerColor}%7C${lat},${lng}&key=${gMap.key}`}
			/>
		</div>
	) : null;
};

GoogleMap.propTypes = {
	className: PropTypes.string,
	isImage: PropTypes.bool,
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired
};

export default GoogleMap;