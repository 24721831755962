import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	loadingBar: {
		position: 'relative',
		display: 'inline-block',
		overflow: 'hidden',
		height: '1rem',
		backgroundColor: 'lightgrey',
		verticalAlign: 'middle',
		width: '100%',
		maxWidth: '7rem',
		minWidth: '3rem',

		'&::after': {
			position: 'absolute',
			display: 'block',
			content: '""',
			width: 'auto',
			height: 'auto',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			background: 'linear-gradient(to right, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 90%)',
			animation: '$loadingAnimation 1920ms linear infinite'
		}
	},
	'@keyframes loadingAnimation': {
		from: {
			transform: 'translate3d(-100%, 0, 0)',
		},
		to: {
			transform: 'translate3d(100%, 0, 0)',
		}
	}
})
);