import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden'
	},
	topBar: {
		zIndex: 10,
		position: 'relative'
	},
	container: {
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden',
		height: '100%',
	},
	content: {
		overflowY: 'auto',
		flex: '1 1 auto',
		backgroundColor: theme.palette.common.white,
	}
}));