import { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { translate, isFullString, isObject } from '../../../shared/utility';
import { useSelectData } from '../../../shared/hooks';
import {
	Card, CardActions, CardContent, CardHeader, Checkbox, Divider, Typography, Table, TableBody, TableCell,
	TableHead, TablePagination, TableRow, TableSortLabel, Box
} from '@mui/material';
import { TableEditBar } from '../../elements';
import { LoadingBar } from '../../loading';
import { useStyles } from './style';

const CustomTable = props => {
	const { className, title, total, header, body, data, handleSorting, orderBy, order, page, handlePageChange, rowsPerPage, setRowsPerPage, hasSelectionEnabled, editActionButtons, language, timePicker, isNotPaginate, titleWithInformation, noTitle, cellStyle } = props;

	const classes = useStyles();


	const [selected, setSelected] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);

	const selectData = useSelectData(selected, setSelected, dataSelected, setDataSelected, data);

	useEffect(() => {
		setSelected([]);
	}, [page, rowsPerPage]);

	const handleChangeRowsPerPage = event => setRowsPerPage(event.target.value);

	const onPageChange = (e, page) => handlePageChange(page + 1);

	const handleLabelDisplayedRows = ({ from, to, count }) => `${from}-${to} ${translate('ui.of', language)} ${count !== -1 ? count : '0'}`;

	const onSorting = cellName => () => handleSorting(cellName);

	const handleSelect = (rIndex, event) => () => selectData.handleSelectOne(event, rIndex);

	const actions = {
		changeRows: handleChangeRowsPerPage,
		changePage: onPageChange,
		displayedRows: handleLabelDisplayedRows,
		sorting: onSorting,
		handleSelect: handleSelect
	};

	return (
		<div
			className={clsx(classes.root, className)}
		>
			<Card>
				{noTitle ? null : isFullString(title) ? (
					<CardHeader
						title={title}
					/>
				) : timePicker ? <Box className={classes.timePicker} display='flex'>{timePicker}</Box>
					: isObject(titleWithInformation) ?
						<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
							<Typography variant='h5'>{titleWithInformation.header}</Typography>
							{titleWithInformation.sideHeader ?
								<Typography variant='h6'>{`${translate('ui.label.total', language)}: ${titleWithInformation.sideHeader}`}</Typography>
								: ''}
						</Box>
						:
						<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
							<LoadingBar />
						</Box>

				}
				{noTitle ? null : <Divider />}
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										{hasSelectionEnabled ? (
											<TableCell padding="checkbox">
												<Checkbox
													checked={selected.length === body.length}
													color="primary"
													indeterminate={
														selected.length > 0 &&
														selected.length < body.length
													}
													onChange={selectData.handleSelectAll}
												/>
											</TableCell>
										) : null}
										{header.map(cell => (
											<TableCell key={cell.name}>
												{cell.hasSorting ? (
													<TableSortLabel
														active={orderBy === cell.name}
														direction={order === 'asc' ? 'desc' : 'asc'}
														onClick={actions.sorting(cell.name)}
													>
														{cell.content}
													</TableSortLabel>
												) : cell.content}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{body.map((row, rIndex) => (
										<TableRow
											key={rIndex}
										>
											{hasSelectionEnabled ? (
												<TableCell padding="checkbox">
													<Checkbox
														checked={selected.indexOf(rIndex) !== -1}
														color="primary"
														onChange={actions.handleSelect(rIndex)}
														value={selected.indexOf(rIndex) !== -1}
													/>
												</TableCell>
											) : null}
											{row.map((cell, cIndex) => (
												<TableCell className={isFullString(cellStyle) ? cellStyle : ''} key={cIndex} >
													{cell.loading ? <LoadingBar /> : cell.content ? cell.content : <p>-</p>}
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{isNotPaginate ? null :
					<CardActions className={classes.actions}>
						<TablePagination
							component="div"
							count={total}
							labelDisplayedRows={actions.displayedRows}
							labelRowsPerPage={translate('ui.rowsPerPage', language)}
							onPageChange={actions.changePage}
							onRowsPerPageChange={actions.changeRows}
							page={page - 1}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={[5, 10, 20, 40, 50]}
						/>
					</CardActions>
				}

				<TableEditBar
					actionButtons={editActionButtons}
					selected={dataSelected}
				/>
			</Card>
		</div>
	);
};

CustomTable.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	total: PropTypes.number,
	timePicker: PropTypes.object,
	isNotPaginate: PropTypes.bool,
	handleSorting: PropTypes.func,
	handlePageChange: PropTypes.func,
	orderBy: PropTypes.string,
	order: PropTypes.string,
	header: PropTypes.array,
	body: PropTypes.array,
	data: PropTypes.array,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	setRowsPerPage: PropTypes.func,
	hasSelectionEnabled: PropTypes.bool,
	editActionButtons: PropTypes.array,
	language: PropTypes.string,
	cellStyle: PropTypes.string,
	titleWithInformation: PropTypes.object,
	noTitle: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

export default connect(mapStateToProps)(CustomTable);