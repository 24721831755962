import { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { SnackbarProvider } from 'notistack';
import * as actions from './store/actions';

import { usePrevious } from './shared/hooks';
import { isObject, isNull, customDebounce } from './shared/utility';

import { routes } from './routes/routes';
import './scss/index.scss';

import { Button } from '@mui/material';

function App(props) {
	const { oidcUser, isLoadingUser, userRedirect, onSetWindowDimensions, onSetGlobalState, history, onSetLocalization, currentUser } = props;
	const access_token = isObject(oidcUser) ? oidcUser.access_token : null;
	const identityUserRole = isObject(oidcUser) ? oidcUser.profile.role : '';

	const { loading: currentUserLoading } = currentUser;
	const location = window.location.pathname;
	const prevLocation = usePrevious(window.location.pathname);

	const notistackRef = createRef();
	const onClickDismiss = key => {
		notistackRef.current.closeSnackbar(key);
	};

	useEffect(() => {
		if (location === '/register/start' && prevLocation !== '/register/start') {
			window.location.reload();
		}
	}, [location, prevLocation]);

	useEffect(() => {
		const resizeHandler = () => {
			onSetWindowDimensions();
		};

		const debouncedResizeHandler = customDebounce(() => resizeHandler(), 200);

		resizeHandler();
		window.addEventListener('resize', debouncedResizeHandler);
	}, [onSetWindowDimensions]);

	useEffect(() => {
		if (!isNull(userRedirect)) {
			history.push({ pathname: userRedirect.pathname, search: userRedirect.search ? userRedirect.search : '' });
			onSetGlobalState('userRedirect', null);
		}
	}, [userRedirect, history, onSetGlobalState]);

	useEffect(() => {
		onSetLocalization(localStorage.languageCode);
	}, [currentUserLoading]);

	/* * * * * *
	* RENDER  *
	* * * * * */
	return (
		<SnackbarProvider
			action={key => (
				<Button
					onClick={() => onClickDismiss(key)}
					style={{
						height: '100%',
						left: 0,
						position: 'absolute',
						top: 0,
						width: '100%'
					}}
				><></></Button>
			)}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			autoHideDuration={2500}
			maxSnack={3}
			ref={notistackRef}
			style={{
				maxWidth: '300px',
				display: 'flex',
				justifyContent: 'flex-start',
				fontFamily: 'Poppins, sans-serif',
				fontSize: '12px'
			}}
		>
			{!isLoadingUser && renderRoutes(routes(!!access_token, identityUserRole))}
		</SnackbarProvider>
	);
}

App.propTypes = {
	userRedirect: PropTypes.object,
	history: PropTypes.object,
	organisationStatus: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
	onSetWindowDimensions: PropTypes.func.isRequired,
	onSetGlobalState: PropTypes.func.isRequired,
	oidcUser: PropTypes.shape({
		id_token: PropTypes.string,
		session_state: PropTypes.string,
		access_token: PropTypes.string,
		refresh_token: PropTypes.string,
		token_type: PropTypes.string,
		scope: PropTypes.string,
		profile: PropTypes.object,
		expires_at: PropTypes.number
	}),
	isLoadingUser: PropTypes.bool,
	onSetLocalization: PropTypes.func,
	currentUser: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
};

const mapStateToProps = state => {
	return {
		userRedirect: state.global.userRedirect,
		oidcUser: state.oidc.user,
		isLoadingUser: state.oidc.isLoadingUser,
		currentUser: state.details.currentUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSetWindowDimensions: () => dispatch(actions.setWindowDimensions()),
		onSetGlobalState: (identifier, value) => dispatch(actions.setGlobalState(identifier, value)),
		onSetLocalization: language => dispatch(actions.setLocalization(language))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

