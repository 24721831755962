import { translations } from './translations';
import { weekOptions, weekdays, weekend, changeDateTimeToUTCTime, modifyDate, unsetTime } from './datetime';

/* * * * * * * * * * * * *
 * DEVELOPMENT UTILITIES *
 * * * * * * * * * * * * */
export const isUndefined = value => (typeof value === 'undefined');
export const isDefined = value => !isUndefined(value);
export const isNull = value => (!value && typeof value === 'object');
export const isNumber = value => (typeof value === 'number');
// No support for Number.isInteger by IE
// export const isInteger = (value) => (Number.isInteger(value));
export const isInteger = value => (isNumber(value) && isFinite(value) && Math.floor(value) === value);
export const isBoolean = value => (typeof value === 'boolean');
export const isString = value => (typeof value === 'string');
export const isFullString = value => (isString(value) && !!value.trim().length);
export const isEmptyString = value => (isString(value) && !value.trim().length);
export const isArray = value => (typeof value === 'object' && Array.isArray(value));
export const isFullArray = value => (isArray(value) && !!value.length);
export const isEmptyArray = value => (isArray(value) && !value.length);
export const isSymbol = value => (typeof value === 'symbol');
export const isObject = value => (typeof value === 'object' && !isNull(value) && !isArray(value));
export const isEmptyObject = value => (isObject(value) && Object.keys(value).length === 0);
export const isFunction = value => (typeof value === 'function');

export const capitalizeFirstCharacter = (value, othersLowercase = true) => isFullString(value) ? (
	`${value.slice(0, 1).toUpperCase()}${value.length > 1 ? (othersLowercase ? value.slice(1).toLowerCase() : value.slice(1)) : ''}`
) : '';
export const getUserPreferences = () => (localStorage.getItem('userData') && isObject(JSON.parse(localStorage.getItem('userData')).preferences) ? JSON.parse(localStorage.getItem('userData')).preferences : null);
export const isEven = number => number % 2 === 0;
export const isOdd = number => Math.abs(number % 2) === 1;

export const decimalBase = (value, currencySymbol) => isNumber(value) && value > 0 ? `${currencySymbol}${value.toFixed(2)}` : '-';

export const customDebounce = (func, delay) => {
	let timeoutId;

	return (...args) => {
		const context = this;

		// Clear the previous timeout
		clearTimeout(timeoutId);

		// Set a new timeout
		timeoutId = setTimeout(() => {
			func.apply(context, args);
		}, delay);
	};
};

//biteToSize
export const bytesToSize = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

//dataURItoFile
export const dataURItoFile = (dataURI, fileName) => {
	const arr = dataURI.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], fileName, { type: mime });
};

/* * * * * * * * * * * *
 * USER DATA UTILITIES *
 * * * * * * * * * * * */
export const getLanguageCode = () => (localStorage.getItem('languageCode'));

/* * * * * * * * * * *
 * STRING UTILITIES  *
 * * * * * * * * * * */
export const translate = (key, language = 'nl', fallback) => {
	if (window.location.hash === '#debug') {
		return key;
	}

	return !key || !translations[language] ? '' :
		key.split('.').reduce((lookup, currentKey) => (
			isString(lookup) ? lookup : (!isUndefined(lookup[currentKey]) ? lookup[currentKey] : (fallback || (() => {
				console.warn(`"${key}" isn't translated in "${language}"!`);
				return key;
			})()))
		), translations[language]);
};

export const getInitials = (name = '') =>
	name
		.replace(/\s+/, ' ')
		.split(' ')
		.slice(0, 2)
		.map(v => v && v[0].toUpperCase())
		.join('');

export const stringifyQueryParams = (params = {}) => {
	if (isObject(params)) {
		return Object.entries(params).map((param, index) => (`${!index ? '?' : '&'}${param[0]}=${param[1]}`)).join('');
	}

	return params;
};

export const uploadImages = (id = null, images = [], onUpload = () => { }) => {
	onUpload(id, images.map(image => (dataURItoFile(image.uri, image.name))));
};

export const createArray = obj => obj ? Object.entries(obj).map(item => ({ id: item[0], config: item[1] })) : [];
export const stringToArray = input => (isArray(input) ? input : isString(input) ? input.split(' ') : []);
export const addToArray = (newItems, currentArray) => (
	stringToArray(currentArray)
		.concat(stringToArray(newItems))
		.filter((value, index, outputArray) => value && outputArray.indexOf(value) === index)
);

export const ignoreKeyClick = (event, keyName) => {
	event.persist();
	if (event.key === keyName) {
		event.preventDefault();
	}
};

export const castMinutesToTimeString = (minutes, language, short = true) => {
	const days = Math.floor(minutes / 1440);
	const hours = Math.floor((minutes % 1440) / 60);
	const leftOverMinutes = minutes % 60;

	return short ? (
		`${days > 0 ? `${days}${translate('ui.daysShort', language)}` : ''}${hours > 0 ? `${hours}${translate('ui.hoursShort', language)}` : ''}${leftOverMinutes > 0 ? `${leftOverMinutes}${translate('ui.minutesShort', language)}` : ''}`
	) : (
		`${days > 0 ? `${days} ${days === 1 ? translate('ui.dayLong', language) : translate('ui.daysLong', language)}` : ''}${days > 0 && hours > 0 ? ' ' : ''}${hours > 0 ? `${hours} ${hours === 1 ? translate('ui.hour', language) : translate('ui.hoursDuration', language)}` : ''}${(hours > 0 && leftOverMinutes > 0) || (hours === 0 && days > 0 && leftOverMinutes > 0) ? ' ' : ''}${leftOverMinutes > 0 ? `${leftOverMinutes} ${leftOverMinutes === 1 ? translate('ui.minute', language) : translate('ui.minutes', language)}` : ''}`
	);
};

export const createSummaryPeriod = (periodsArray, isSummary = false) => {
	const newPeriodArray = periodsArray.map(period => {
		const dayStringsArray = period.days.split(', ');
		const days = dayStringsArray.map(dayString => weekOptions.filter(option => option.value === dayString)[0]);

		const endDate = changeDateTimeToUTCTime(new Date(period.end));

		return {
			...period,
			days,
			start: changeDateTimeToUTCTime(new Date(period.start)),
			end: endDate.getHours() === 23 && endDate.getMinutes() === 59 && endDate.getSeconds() === 59 ? modifyDate(unsetTime(endDate), { date: '+1' }) : endDate,
		};
	});

	return isSummary ? newPeriodArray : createEditableDaysArrayInPricingModel(newPeriodArray);
};

export const createEditableDaysArrayInPricingModel = pricingModel => {
	const newPricingModel = pricingModel.map(entry => {
		return {
			...entry,
			days: entry.days.reduce((acc, cur) => {
				return [
					...acc,
					...(cur.value === 'weekdays' ? weekdays : []),
					...(cur.value === 'weekend' ? weekend : []),
					...(cur.value === 'allWeek' ? [...weekdays, ...weekend] : []),
					...(cur.value !== 'weekdays' && cur.value !== 'weekend' && cur.value !== 'allWeek' ? [cur] : [])
				];
			}, [])
		};
	});

	return newPricingModel;
};

export const timeConvert = value => ({
	minutes: value % 60,
	hours: Math.floor(value / 60) % 24,
	day: Math.floor(value / (60 * 24)),
});

export const getUrlParams = queryString => {
	const decoded = decodeURIComponent(decodeURIComponent(unescape(queryString)));
	const hashes = decoded.slice(decoded.indexOf('?') + 1).split('&');
	const params = {};
	hashes.map(hash => {
		// const [key, val] = hash.split('=');
		const key = hash.slice(0, hash.indexOf('='));
		const val = hash.slice(hash.indexOf('=') + 1);
		params[key] = decodeURIComponent(val);
	});
	return params;
};

export const handleHubReference = value => {
	if (isObject(value) && isObject(value.address)) {
		const { street, number, city } = value.address;
		if (isObject(value.address)) {
			return `${isUndefined(street) ? '' : street} ${isUndefined(number) ? '' : number}, ${isUndefined(city) ? '' : city}`;
		}
	} else {
		return '-';
	}
};

export const instanceName = value => {
	const category = value.categoryReference.type;
	if (category === 'cars') {
		return value.itemCar.licensePlate;
	} else if (category === 'bikes') {
		return value.itemBike.bikeNumber;
	} else if (category === 'offices') {
		return value.itemOffice.roomNumber;
	} else if (category === 'trailers') {
		return value.itemTrailer.trailerNumber;
	} else if (category === 'parkingLots') {
		return value.itemParkingLot.parkingNumber;
	} else if (category === 'assets') {
		return value.itemAsset.assetNumber;
	} else {
		return '-';
	}
};