import { makeStyles } from '@mui/styles';

import { colors } from '@mui/material';

export const useStyles = makeStyles(theme => ({
	root: {
		marginRight: 0
	},
	popperWrapper: {
		top: 8
	},
	popper: {
		width: '100%',
		borderRadius: 18,
		overflow: 'auto',
		paddingRight: 5
	},
	selectResults: {
		overflow: 'auto',
		'&::-webkit-scrollbar': {
			backgroundColor: 'white',
			'&-thumb': {
				backgroundColor: '#c8c8c8',
				borderRadius: '16px',
				border: '4px solid white',
				'&:hover': {
					backgroundColor: '#868686'
				}
			}
		}
	},
	smallPopper: {
		maxHeight: '150px'
	},
	mediumPopper: {
		maxHeight: '200px'
	},
	largePopper: {
		maxHeight: '250px'
	},
	loading: {
		color: theme.palette.text.secondary,
		padding: '14px 16px'
	},
	empty: {
		color: theme.palette.text.secondary,
		padding: '14px 16px'
	},
	avatar: {
		marginRight: theme.spacing(1),
		width: theme.spacing(4.5),
		height: theme.spacing(4.5)
	},
	active: {
		backgroundColor: colors.grey[300]
	},
	listItem: {},
	iconStyle: {
		width: 14,
		height: 14,
		marginLeft: '7px',
		color: '#c24747'
	},
	checkIconStyle: {
		width: 14,
		height: 14,
		marginLeft: '7px',
		color: '#285a47'
	},
	scheduleIcon: {
		width: 14,
		height: 14,
		marginLeft: '7px',
		color: '#f57c00'
	},
	errorStyle: {
		width: 14,
		height: 14,
		marginLeft: '7px',
		color: '#f57c00'
	},
	inputContainer: {
		padding: 0,
		position: 'relative'
	},
	input: {
		zIndex: 2
	},
	endAdornment: {
		position: 'absolute',
		right: theme.spacing(1)
	},
	defaultText: {
		fontStyle: 'italic'
	}
}));