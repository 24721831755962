import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as actions from '../../../../store/actions';
import { CircularProgress, Box, Typography, Avatar } from '@mui/material';
import { connect } from 'react-redux';
import { ActionDialog } from '../../../../components';
import { translate, getInitials, isFullString } from '../../../../shared/utility';
import { commaTimeStrings } from '../../../../shared/datetime';
import { useStyles } from './style';
import { useSnackbar } from 'notistack';


const UserInvitation = props => {

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const { currentUser, language, onUserInvitation, userInvitation, onUpdateInvite, updateInvite } = props;

	const { data: userData } = currentUser;

	const { data: userInvitationData } = userInvitation;

	const { data: updateInviteData, loading: updateInviteLoading } = updateInvite;

	const cultureInfo = userInvitationData?.cultureInfo?.slice(0, 2) === 'de' ? 'en' : userInvitationData?.cultureInfo?.slice(0, 2);

	const [openInvitation, setOpenInvitation] = useState(false);

	const [acceptLoading, setAcceptLoading] = useState(false);

	const [declineLoading, setDeclineLoading] = useState(false);

	useEffect(() => {
		if (userData?.latestInvitationId) {
			onUserInvitation(userData?.latestInvitationId);
		}
	}, [userData]);

	useEffect(() => {
		if (userData?.latestInvitationId && userInvitationData?.id && isFullString(cultureInfo)) {
			setOpenInvitation(true);
		}
	}, [userData, userInvitationData, cultureInfo]);

	useEffect(() => {
		if (updateInviteData) {
			setAcceptLoading(false);
			setDeclineLoading(false);
			setOpenInvitation(false);
			enqueueSnackbar(acceptLoading ? translate('user.invitation.accept', language) : translate('user.invitation.decline', language), { variant: 'success' });
		}
	}, [updateInviteData]);

	const handleDecline = () => {
		setDeclineLoading(true);
		onUpdateInvite(userData?.latestInvitationId, { state: 'reject' });
	};

	const handleConfirmDialog = () => {
		onUpdateInvite(userData?.latestInvitationId, { state: 'accept' });
		setAcceptLoading(true);
	};

	return (
		<div>
			<ActionDialog
				actionButtonProps={{
					action: handleConfirmDialog,
					text: acceptLoading ? <CircularProgress disableShrink size={19} /> : translate('ui.accept', cultureInfo)
				}}
				decline={declineLoading ? <CircularProgress color='inherit' disableShrink size={19} /> : translate('ui.decline', cultureInfo)}
				declineVarinat={'contained-tertiary'}
				handleClose={handleDecline}
				loading={updateInviteLoading}
				open={openInvitation}
				title={translate('user.invitation.dialog.title', cultureInfo)}
			>
				{userInvitationData?.id ?
					<Box display='flex'>
						<Avatar className={classes.avatar} src={userInvitationData?.organisationReference?.logo}>
							{getInitials(userInvitationData.organisationReference.name)}
						</Avatar>
						<Box display='flex' flexDirection='column' mt={2} pl={2}>
							<Typography variant="h6">{userInvitationData.organisationReference.name}</Typography>
							<Typography variant='body2'>{`${translate('user.invitation.expiryDate', cultureInfo)}: ${commaTimeStrings(userInvitationData.expiryDate)}`}</Typography>
						</Box>
					</Box>
					: null}
				{translate('user.invitation.dialog.description', cultureInfo)}
			</ActionDialog>
		</div>

	);
};

UserInvitation.propTypes = {
	language: PropTypes.string,
	onUserInvitation: PropTypes.func,
	onUpdateInvite: PropTypes.func,
	currentUser: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
	userInvitation: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
	updateInvite: PropTypes.shape({
		data: PropTypes.any,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
	}),
};

const mapStateToProps = state => {
	return {
		currentUser: state.details.currentUser,
		userInvitation: state.details.userInvitation,
		updateInvite: state.details.updateInvite,
		// GLOBAL PROPS
		language: state.global.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUserInvitation: inviteId => dispatch(actions.userInvitation(inviteId)),
		onUpdateInvite: (inviteId, body) => dispatch(actions.updateInvite(inviteId, body))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInvitation);