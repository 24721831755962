import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmptyString, isUndefined } from '../../../shared/utility';

import { Paper, InputBase, IconButton, TextField, MenuItem, Divider } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import { useStyles } from './style';

const Input = props => {
	const classes = useStyles();
	const { events = {}, value, placeholder, icon, hasClearButton = false, inputType, selectOptions = [], className } = props;

	return (
		<Paper className={clsx(classes.root, className)}>
			{!isUndefined(icon) ? icon : null}
			{inputType === 'select' ? (
				<TextField
					InputProps={{ disableUnderline: true }}
					className={classes.input}
					onChange={events.onChange}
					select
					value={value}
					variant='standard'
				>
					{selectOptions.map((option, index) => (
						<MenuItem key={option.value} sx={{ fontWeight: index ? 400 : 500 }} value={option.value}>
							{option.label ? option.label : option.value}
						</MenuItem>
					))}
				</TextField>
			) : (
				<InputBase
					className={classes.input}
					onBlur={events.onBlur}
					onChange={events.onChange}
					onFocus={events.onFocus}
					onKeyUp={events.onKeyUp}
					placeholder={placeholder}
					value={value}
				/>
			)}
			<IconButton className={isEmptyString(value) || !hasClearButton ? classes.hidden : null} onClick={events.onClear} size='small'>
				<ClearIcon />
			</IconButton>
			{inputType === 'search-suggest' ? (
				<>
					{!isEmptyString(value) && hasClearButton ? (
						<Divider className={classes.divider} orientation='vertical' />
					) : null}
					<IconButton onClick={events.onClick} size='small'>
						<SearchIcon />
					</IconButton>
				</>
			) : null}
		</Paper>
	);
};

Input.propTypes = {
	className: PropTypes.string,
	events: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	icon: PropTypes.node,
	hasClearButton: PropTypes.bool,
	inputType: PropTypes.string,
	selectOptions: PropTypes.array
};

export default Input;