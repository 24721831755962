import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { AppBar, Toolbar } from '@mui/material';
import ViewSwitch from './ViewSwitch';
import UserMenu from './UserMenu';

import TopologyLogo from '../../../assets/images/topology_logo_.svg';

import { useStyles } from './style';

const Topbar = props => {
	const { className } = props;

	const classes = useStyles();

	return (
		<AppBar
			className={clsx(classes.root, className)}
		>
			<Toolbar className={classes.toolbar}>
				<RouterLink to="/">
					<img alt='Topology Logo' src={TopologyLogo} width='140px' />
				</RouterLink>
				<ViewSwitch />
				<UserMenu className={classes.menuSection} />
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
};

export default Topbar;
