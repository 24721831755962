import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Card, CardContent, Typography, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './style';

const SelectedOrganisationCard = props => {
	const { className, name, handleClose } = props;
	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>
					{name}
				</Typography>
			</CardContent>
			<IconButton onClick={handleClose} size='small'>
				<CloseIcon />
			</IconButton>
		</Card>
	);
};

SelectedOrganisationCard.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	handleClose: PropTypes.func
};

export default SelectedOrganisationCard;