import userManager from '../../authentication/userManager';
import { store } from '../../index';
import { updateRedirect } from './global';
import { getLanguageCode, isString } from '../../shared/utility';
const API_BASE = process.env.REACT_APP_API_BASE; //official api base

const browserUrl = window.location;
const baseUrl = browserUrl.protocol + '//' + browserUrl.host;

const handleResponses = async (response = null, preventRedirect = false, isBlob = false) => {
	let errorMessage = '';

	if (response && response.type) {
		if (response.ok) {
			switch (response.status) {
				case 200:
				case 201:
					return !isBlob ? await response.json() : await response.blob();
				case 202:
				case 204:
					return true;
				default:
					console.log('THIS SHOULD NOT HAPPEN!: ', response);
					return true;
			}
		} else {
			let errorData = null;
			switch (response.status) {
				case 400:
					errorData = await response.json();
					throw errorData;
				case 401:
					try {
						console.log('401: trying to silently renew token');
						userManager.signinSilent();
					} catch (error) {
						console.log('401: could not silently renew token, logging out');
						console.log(error);
						console.log('401 & token expired: logout!!');
						userManager.signoutRedirect({
							id_token_hint: store.getState().oidc.user.id_token
						});
						errorMessage = 'Unauthorized';
						throw errorMessage;
					}
					break;
				case 403:
					errorMessage = 'Forbidden';
					if (!preventRedirect) {
						store.dispatch(updateRedirect(`/errors/error${errorMessage}`, null));
					}
					throw errorMessage;
				case 404:
					errorMessage = 'Not Found';
					if (!preventRedirect && (response.url.slice(API_BASE.length) !== 'api/v1/organisations/me/status')) {
						store.dispatch(updateRedirect(`/errors/error${errorMessage}`, null));
					}
					throw errorMessage;
				case 429:
					errorMessage = 'Too many attempts';
					if (!preventRedirect) {
						store.dispatch(updateRedirect(`/errors/error${errorMessage}`, null));
					}
					throw errorMessage;
				case 500:
					errorMessage = 'Server error';
					if (!preventRedirect) {
						store.dispatch(updateRedirect(`/errors/error${errorMessage}`, null));
					}
					throw errorMessage;
				default:
					errorMessage = 'Something went wrong...';
					if (!preventRedirect) {
						store.dispatch(updateRedirect(`/errors/error${errorMessage}`, null));
					}
					throw errorMessage;
			}
		}
	} else {
		errorMessage = response && response.message ? response.message : (response ? response : 'Missing error message.');
		throw errorMessage;
	}
};

const fetchData = async (methodType, path, bodyData, preventRedirect = false, isFormData = false, isBlob = false) => {
	//const language = isString(getLanguageCode()) ? getLanguageCode() : '';
	const language = isString(getLanguageCode()) ? getLanguageCode() : '';

	const requestHeaders = new Headers();
	if (!isFormData) {
		if (!isBlob) {
			requestHeaders.append('Content-Type', 'application/json-patch+json'); //was 'application/json-patch+json', put this back when working with the actual api
		}
		requestHeaders.append('Accept-Language', language);
	}

	const token = store.getState().oidc.user.access_token;

	if (token && (path !== 'connect/token')) {
		requestHeaders.append('Authorization', `Bearer ${token}`);
	}

	requestHeaders.append('Access-Controll-Allow-Origin', baseUrl);

	let data = '';

	if (isFormData) {
		data = {
			method: methodType,
			headers: requestHeaders,
			body: bodyData,
		};
	} else {
		data = {
			method: methodType,
			headers: requestHeaders,
			body: bodyData ? JSON.stringify(bodyData) : null,
		};
	}


	const endpoint = `${API_BASE}${(path === 'connect/token') ? '' : 'api/v1/'}${path}`;

	try {
		const request = new Request(endpoint, data);
		const response = await fetch(request);
		return handleResponses(response, preventRedirect, isBlob);
	} catch (error) {
		return handleResponses(error, preventRedirect);
	}
};

export default {
	get: async (path = '', preventRedirect = false, isFormData = false, isBlob = false) => (await fetchData('GET', path, undefined, preventRedirect, isFormData, isBlob)),
	post: async (path = '', body = undefined, preventRedirect = false, isFormData = false) => (await fetchData('POST', path, body, preventRedirect, isFormData)),
	put: async (path = '', body = undefined, preventRedirect = false, isFormData = false) => (await fetchData('PUT', path, body, preventRedirect, isFormData)),
	patch: async (path = '', body = undefined, preventRedirect = false, isFormData = false) => (await fetchData('PATCH', path, body, preventRedirect, isFormData)),
	delete: async (path = '', preventRedirect = false, isFormData = false) => (await fetchData('DELETE', path, undefined, preventRedirect, isFormData)),
};

// This class keeps count of the amount calls done
// for a particular action. This is done so we can make
// sure only the result of the latest call done is returned
// to the app. This is so we can prevent unnecessary
// component updates
export class Counter {
	constructor(states = []) {
		// ['stateNameA', 'stateNameB'] =>
		// { stateNameA: 0, stateNameB: 0 }
		this.counters = states.reduce((acc, identifier) => ({
			...acc,
			[identifier]: 0,
		}), {});
	}

	increment(identifier) {
		this.counters[identifier]++;
		return this.counters[identifier];
	}

	reset(identifier) {
		this.counters[identifier] = 0;
	}

	isCurrent(identifier, value) {
		return this.counters[identifier] === value;
	}
}