import { colors } from '@mui/material';

import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			color: palette.icon,
			'&:hover': {
				backgroundColor: colors.grey[100]
			},
			'&.Mui-selected': {
				backgroundColor: '#FFF',
				color: palette.primary.main,
				'&:hover': {
					backgroundColor: colors.grey[50]
				}
			},
			'&:first-of-type': {
				borderTopLeftRadius: 20,
				borderBottomLeftRadius: 20
			},
			'&:last-child': {
				borderTopRightRadius: 20,
				borderBottomRightRadius: 20
			}
		}

	}
};
