import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

export const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 13,
		lineHeight: 2,
		padding: theme.spacing(2)
	}
}))(Tooltip);