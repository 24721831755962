import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { Search, StyledButton } from '../../../components';
import MainFilter from './MainFilter';
import ExtraButtons from './ExtraButtons';
import { isObject, isFullArray, translate } from '../../../shared/utility';
import { useStyles } from './style';
import TimePicker from './TimePicker';
import DownloadButton from './DownloadButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

const SearchBar = props => {
	const {
		placeholder, hideSearch, hasMainFilter, mainFilters, hasExtraButtons, extraButtons, searchEvents, searchValue, periodFilter, timePickerData, downloadData,
		clearFilters, language
	} = props;

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.searchFilterContainer}>
				{hideSearch ? null : (
					<Search
						className={classes.search}
						events={searchEvents}
						hasInlineSearchButton
						inputType='search-suggest'
						placeholder={placeholder}
						value={searchValue}
					/>
				)}
				{periodFilter}
				{isObject(timePickerData) ? (
					<TimePicker {...timePickerData} />
				) : null}

				{hasMainFilter ? mainFilters.map((mainFilter, index) => (
					<MainFilter
						className={classes.mainFilter}
						dataList={mainFilter.dataList}
						defaultListItem={mainFilter.defaultListItem}
						events={mainFilter.events}
						isSearchAutocomplete={mainFilter.isSearchAutocomplete}
						isSelectWithLazyLoading={mainFilter.isSelectWithLazyLoading}
						key={index}
						listType={mainFilter.listType}
						onFetchData={mainFilter.onFetchData}
						placeholder={mainFilter.placeholder}
						selectOptions={mainFilter.selectOptions}
						value={mainFilter.value}
					/>
				)) : null}
				<Box alignSelf='flex-start' display='flex'>
					{isObject(clearFilters) && clearFilters.clear ?
						<StyledButton
							onClick={clearFilters.action}
							size='medium'
							startIcon={<RefreshIcon />}
							variant='inline-default'
						>
							{translate(clearFilters.btnText, language)}
						</StyledButton>
						: null}
				</Box>
			</Box>
			{hasExtraButtons ? (
				<Box className={classes.extraButtonsContainer}>
					{isObject(downloadData) && <DownloadButton downloadData={downloadData} />}
					{isFullArray(extraButtons) && <ExtraButtons buttons={extraButtons} />}
				</Box>

			) : null}
		</Box>
	);
};

SearchBar.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	searchEvents: PropTypes.object,
	searchValue: PropTypes.string,
	hideSearch: PropTypes.bool,
	hasMainFilter: PropTypes.bool,
	mainFilters: PropTypes.arrayOf(Object),
	hideMoreFilters: PropTypes.bool,
	hasExtraButtons: PropTypes.bool,
	extraButtons: PropTypes.arrayOf(Object),
	onFilter: PropTypes.func,
	language: PropTypes.string,
	width: PropTypes.string,
	periodFilter: PropTypes.object,
	timePickerData: PropTypes.object,
	clearFilters: PropTypes.object,
	downloadData: PropTypes.object
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

export default connect(mapStateToProps)(withWidth()(SearchBar));