/* eslint-disable react/no-multi-comp */
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useRouter } from '../../../shared/hooks';

import { List, Typography } from '@mui/material';
import NavigationListItem from './NavigationListItem';

import { useStyles } from './style';

const NavigationList = props => {
	const { pages, ...rest } = props;
	const classes = useStyles();
	return (
		<List className={classes.navList}>
			{pages.reduce(
				(items, page) => reduceChildRoutes({ items, page, ...rest }),
				[]
			)}
		</List>
	);
};

NavigationList.propTypes = {
	depth: PropTypes.number,
	pages: PropTypes.array
};

const reduceChildRoutes = props => {
	const { router, items, page, depth } = props;

	if (page.children) {
		const open = matchPath(router.location.pathname, {
			path: page.href,
			exact: false
		});
		const key = `${page.title}-${page.href}`;
		items.push(
			<NavigationListItem
				depth={depth}
				icon={page.icon}
				key={key}
				label={page.label}
				open={Boolean(open)}
				title={page.title}
			>
				<NavigationList
					depth={depth + 1}
					pages={page.children}
					router={router}
				/>
			</NavigationListItem>
		);
	} else {
		const key = `${page.title}-${page.href}`;
		items.push(
			<NavigationListItem
				depth={depth}
				href={page.href}
				icon={page.icon}
				key={key}
				label={page.label}
				title={page.title}
			/>
		);
	}

	return items;
};

const Navigation = props => {
	const { title, pages, className, component: Component, ...rest } = props;

	const classes = useStyles();
	const router = useRouter();

	return (
		<Component
			{...rest}
			className={clsx(classes.root, className)}
		>
			{title && <Typography variant="overline">{title}</Typography>}
			<NavigationList
				depth={0}
				pages={pages}
				router={router}
			/>
		</Component>
	);
};

Navigation.propTypes = {
	className: PropTypes.string,
	component: PropTypes.any,
	pages: PropTypes.array.isRequired,
	title: PropTypes.string
};

Navigation.defaultProps = {
	component: 'nav'
};

export default Navigation;
