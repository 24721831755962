import { SET_FILTER, RESET_FILTERS } from '../actions/actionTypes';
import { isFullArray, isFullString, isUndefined, isNull } from '../../shared/utility';

const initialState = {
	userFilterValue: null
};

const setFilter = (state, action) => {
	const { identifier, value } = action;

	if (isNull(identifier) || state[identifier] === value) {
		return state;
	}

	const newState = { ...state, [identifier]: value };

	return newState;
};

const resetFilters = (state, action) => {
	let { identifiers } = action;

	if (!isFullArray(identifiers) && !isFullString(identifiers)) {
		identifiers = Object.keys(initialState);
	} else if (isFullString(identifiers)) {
		identifiers = [identifiers];
	}

	return identifiers
		.filter(key => !isUndefined(initialState[key]))
		.reduce((newState, identifier) => setFilter(newState, { identifier, value: initialState[identifier] }), state);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FILTER: return setFilter(state, action);
		case RESET_FILTERS: return resetFilters(state, action);
		default: return state;
	}
};

export default reducer;