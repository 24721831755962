import { Fragment, useRef, useState, memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ListItemIcon, ListItemText, Tooltip, Menu, MenuItem, Button, CircularProgress } from '@mui/material';
import { useStyles } from './style';

const MyButton = forwardRef((props, ref) => {
	return <Button ref={ref} {...props} />;
});

const MoreButton = props => {

	const { className, menuItems, startIcon, text, disabled } = props;

	const classes = useStyles();

	const moreRef = useRef(null);

	const origin = { vertical: 'top', horizontal: 'left' };

	const [openMenu, setOpenMenu] = useState(false);

	const handleMenuOpen = () => setOpenMenu(true);

	const handleMenuClose = () => setOpenMenu(false);


	return (
		<Fragment>
			<Tooltip title="More options">
				<MyButton
					className={className}
					disabled={disabled}
					onClick={handleMenuOpen}
					ref={moreRef}
					size="large"
					startIcon={disabled ? <CircularProgress color='info' disableShrink size={24} /> : startIcon}
					variant='outlined'
				>
					{text}
				</MyButton>
			</Tooltip>
			<Menu
				anchorEl={moreRef.current}
				anchorOrigin={origin}
				onClose={handleMenuClose}
				open={openMenu}
				transformOrigin={origin}
			>
				{menuItems.map((menuItem, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							menuItem.action();
							handleMenuClose();
						}}
					>
						<ListItemIcon className={clsx({ [classes.red]: menuItem.isRed })}>{menuItem.icon}</ListItemIcon>
						<ListItemText primary={menuItem.text} primaryTypographyProps={{ className: clsx({ [classes.red]: menuItem.isRed }) }} />
					</MenuItem>
				))}
			</Menu>
		</Fragment>
	);
};

MoreButton.propTypes = {
	className: PropTypes.string,
	menuItems: PropTypes.array.isRequired,
	startIcon: PropTypes.any,
	text: PropTypes.string,
	disabled: PropTypes.bool
};

export default memo(MoreButton);