import { SET_GLOBAL_STATE, UPDATE_REDIRECT_OBJECT, SET_LOCALIZATION, SET_WINDOW_DIMENSIONS } from '../actions/actionTypes';
import { isArray, isUndefined } from '../../shared/utility';

const initialState = {
	language: null,
	localization: {
		available: null,
		locale: null,
		language: null,
		region: null
	},
	// values
	userRedirect: null,
	// ui
	windowDimensions: {
		width: window.innerWidth,
		height: window.innerHeight
	},
};

// set global state's value
const setGlobalState = (state, action) => {
	const updatedStates = isArray(action.identifier) ? action.identifier.reduce((keys, currentKey) => {
		keys[currentKey] = !isUndefined(action.value) ? action.value : false;
		return keys;
	}, {}) :
		{ [action.identifier]: !isUndefined(action.value) ? action.value : !state[action.identifier] };

	return { ...state, ...updatedStates };
};

const updateRedirect = (state, action) => {
	// optional object properties
	const updatedRedirect = {
		...(action.pathname && { pathname: action.pathname }),
		...(action.search && { search: action.search })
	};

	const updatedState = { ...state.userRedirect, ...updatedRedirect };
	return {
		...state, ...{
			userRedirect: updatedState
		}
	};
};

// UI
const setWindowDimensions = (state, action) => ({ ...state, ...{ windowDimensions: action.windowDimensions } });

// LOCALIZATION
const setLocalization = (state, action) => {
	const updatedLocalization = {
		...state.localization, ...{
			available: action.available,
			locale: action.locale,
			language: action.language,
			region: action.region
		}
	};

	return { ...state, language: updatedLocalization.language, ...{ localization: updatedLocalization } };
};

/* * * * * * * * * *
 * REDUCER METHOD  *
 * * * * * * * * * */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_GLOBAL_STATE: return setGlobalState(state, action);
		case UPDATE_REDIRECT_OBJECT: return updateRedirect(state, action);
		case SET_WINDOW_DIMENSIONS: return setWindowDimensions(state, action);
		case SET_LOCALIZATION: return setLocalization(state, action);
		default: return state;
	}
};

export default reducer;