import PropTypes from 'prop-types';

import { isUndefined } from '../../../shared/utility';

import { LightTooltip } from './style';

const InfoPopup = props => {
	const { heading, title, children, open } = props;

	return (
		<LightTooltip
			title={
				<>
					{heading ? (<h4>{heading}</h4>) : null}
					{title}
				</>
			}
			{...(!isUndefined(open) && { open })}
		>
			{children}
		</LightTooltip>
	);
};

InfoPopup.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	heading: PropTypes.string,
	open: PropTypes.bool
};

export default InfoPopup;