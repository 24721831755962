import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import theme from '../../../theme';

import { useStyles } from './style';

const Chart = props => {
	const { className, data: dataProp, labels } = props;

	const classes = useStyles();

	const data = canvas => {
		const ctx = canvas.getContext('2d');
		const gradient = ctx.createLinearGradient(0, 0, 0, 60);

		gradient.addColorStop(0, 'rgba(65, 138, 201, 0.2)');
		gradient.addColorStop(1, 'rgba(65, 138, 201, 0)');

		return {
			datasets: [
				{
					data: dataProp.bookingData,
					backgroundColor: gradient,
					borderColor: theme.palette.primary.main,
					borderWidth: 1,
					fill: 'start',
					pointBorderColor: theme.palette.primary.main,
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointRadius: 4,
					cubicInterpolationMode: 'monotone'
				}
			],
			labels
		};
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: false,
		layout: {
			padding: {
				left: -4,
				right: 10,
				top: 7,
				bottom: -2
			}
		},
		scales: {
			x: {
				beginAtZero: true,
				grid: {
					display: false,
					drawBorder: false
				},
				ticks: {
					display: false,
				}
			},
			y: {
				suggestedMax: dataProp.bookingTotal,
				beginAtZero: true,
				grid: {
					display: false,
					drawBorder: false
				},
				ticks: {
					display: false,
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: true,
				mode: 'index',
				intersect: false,
				caretSize: 5,
				padding: {
					x: 7,
					y: 7
				},
				borderWidth: 1,
				borderColor: theme.palette.divider,
				backgroundColor: theme.palette.white,
				titleColor: theme.palette.text.primary,
				bodyColor: theme.palette.text.primary,
				footerColor: theme.palette.text.primary,
				displayColors: false,
				callbacks: {
					title: () => { },
					label: function (tooltipItem) {
						const label = `${tooltipItem.parsed.y}/${dataProp.bookingTotal}`;
						return label;
					}
				}
			}
		},
	};

	return (
		<div
			className={clsx(classes.root, className)}
		>
			<Line
				data={data}
				options={options}
			/>
		</div>
	);
};

Chart.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
	labels: PropTypes.array.isRequired
};

export default Chart;