import PropTypes from 'prop-types';
import { TextField, Paper, Box } from '@mui/material';
import { useStyles } from '../style';

const TimePicker = props => {

	const { onChangeStart, onChangeEnd } = props;


	const classes = useStyles();
	return (
		<Paper className={classes.timePop}>
			<Box component="form" mr={1} >
				<TextField
					InputLabelProps={{ shrink: true, }}
					InputProps={{ disableUnderline: true, }}
					id="datetime-local"
					type="datetime-local"
					variant="standard"
					onChange={onChangeStart}
					// defaultValue="2017-05-24T10:30"
					sx={{ width: 150 }}
				/>
			</Box>
			<Box component="form" >
				<TextField
					InputLabelProps={{ shrink: true, }}
					InputProps={{ disableUnderline: true, }}
					id="datetime-local"
					type="datetime-local"
					variant="standard"
					onChange={onChangeEnd}
					// defaultValue="2017-05-24T10:30"
					sx={{ width: 150, }}
				/>
			</Box>
		</Paper>
	);
};

TimePicker.propTypes = {
	onChangeStart: PropTypes.func,
	onChangeEnd: PropTypes.func
};

export default TimePicker;