import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({

	tipCard: {
		backgroundColor: '#e4eefb',
		color: '#15263e',
		boxShadow: 'none',
		padding: '0.632rem',
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		flexShrink: 3,
		width: 'fit-content'
	},
	tip: {
		...theme.typography.pagination,
	},
	icon: {
		marginRight: theme.spacing(1)
	},
}));
