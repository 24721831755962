import { isString, isInteger, isFullString, isFullArray, isArray } from './utility';

/* * * * * * * * *
 * LOCALIZATION  *
 * * * * * * * * */

export const localizeDateTime = (dateTime, locale = 'nl-NL', opts = {}) => {
	const options = {
		// timeZone: 'Europe/Amsterdam',
		...opts
	};
	const date = new Date(dateTime);
	const dateObject = !isNaN(date) ? date : Date.now();
	return Intl ? Intl.DateTimeFormat(locale, options).format(dateObject) : dateObject.toLocaleDateString(locale, options);
};

export const localizeDateTimeComma = (dateTime, locale = 'nl-NL', opts = {}, time = {}) => {
	const options = {
		// timeZone: 'Europe/Amsterdam',
		...opts
	};
	const timeOptions = {
		...time
	};
	const date = new Date(dateTime);
	const dateObject = !isNaN(date) ? date : Date.now();
	return Intl ? `${Intl.DateTimeFormat(locale, options).format(dateObject)}, ${Intl.DateTimeFormat(locale, timeOptions).format(dateObject)}` : dateObject.toLocaleDateString(locale, options);
};


export const commaTimeStrings = value => {
	return localizeDateTimeComma(value, undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }, { hour: '2-digit', minute: '2-digit' });
};

/* * * * * * * * * *
 * DATE UTILITIES  *
 * * * * * * * * * */
export const isDate = value => (isArray(value) ? value : [value]).every(val => (val instanceof Date && !isNaN(val)));

export const isDateToday = (date, currentDate = new Date()) => (isDate([date, currentDate]) && sameDates(unsetTime(date), unsetTime(currentDate)));

export const getDateObject = value => isDate(value) ? value : isFullString(value) ? (new Date(value)) : null;

export const getLastDayOfMonth = (year, month) => {
	const date = new Date(year, month + 1, 0);
	return date.getDate();
};

export const sameDates = (firstDate, secondDate, compareTimesOnly = false, compareDaysOnly = false) => {
	if (isDate([firstDate, secondDate])) {
		return (!compareTimesOnly ? compareDaysOnly ? unsetTime(firstDate).getTime() === unsetTime(secondDate).getTime() : firstDate.getTime() === secondDate.getTime() : timeToMS(firstDate) === timeToMS(secondDate));
	} else {
		return (firstDate === secondDate);
	}
};

export const differentDates = (firstDate, secondDate, compareTimesOnly = false, compareDaysOnly = false) => {
	const different = !sameDates(firstDate, secondDate, compareTimesOnly, compareDaysOnly);
	return different;
};

export const isEarlierThan = (date, compare = new Date()) => isDate([date, compare]) ? (date.getTime() < compare.getTime()) : false;
export const isLaterThan = (date, compare = new Date()) => isDate([date, compare]) ? (date.getTime() > compare.getTime()) : false;

export const isEarlierTime = (date, compare = new Date()) => isDate([date, compare]) ? (date.getHours() < compare.getHours() || (date.getHours() === compare.getHours() && date.getMinutes() < compare.getMinutes())) : false;

export const getDateRoundedToDuration = (date, ISODuration, roundUp = false, roundDown = false) => {
	//if the compare date is a few months back as it was before, the difference between
	//the current date and the compare date does not account for Summer/Winter Time
	const compareDate = modifyDate(new Date(), { hours: '-1', minutes: 0 });
	const duration = durationToMS(ISODuration);
	const diff = Math.ceil((date - compareDate) / duration) + (roundUp ? 1 : 0) - (roundDown ? 1 : 0);
	return modifyDate(compareDate, { milliseconds: diff * duration });
};

export const duration = (firstDate, secondDate) => (isDate([firstDate, secondDate]) ? Math.abs(modifyDate(firstDate).getTime() - modifyDate(secondDate).getTime()) : 0);
export const durationToMinutes = durationInMS => (durationInMS / 60000);

export const unsetTime = date => (isDate(date) ? modifyDate(new Date(date), { hours: 0, minutes: 0 }) : date);

export const sortDates = (firstDate, secondDate, desc = false) => {
	firstDate = getDateObject(firstDate);
	secondDate = getDateObject(secondDate);

	const result = Math.sign(firstDate.getTime() - secondDate.getTime());
	return !desc ? result : -result;
};

export const modifyDate = (date, components = {}, resetSeconds = true) => {
	if (isDate(date)) {
		const newDate = new Date(date);
		if (isFullArray(Object.keys(components))) {
			// eslint-disable-next-line no-unused-vars
			for (const key in components) {
				if (!['fullYear', 'month', 'date', 'hours', 'minutes', 'seconds', 'milliseconds'].includes(key)) {
					continue;
				}

				const value = components[key];
				const prop = `${key.slice(0, 1).toUpperCase()}${key.slice(1)}`;

				const newValue = isDate(value) ? value[`get${prop}`]() :
					isString(value) && ['-', '+'].includes(value.substr(0, 1)) ?
						value.substr(0, 1) === '+' ?
							newDate[`get${prop}`]() + parseInt(value.substr(1), 10) :
							newDate[`get${prop}`]() - parseInt(value.substr(1), 10) :
						parseInt(value, 10);

				newDate[`set${prop}`](newValue);
			}
		}
		if (resetSeconds) {
			newDate.setSeconds(0);
			newDate.setMilliseconds(0);
		}
		return newDate;
	}

	return date;
};

export const timeToMS = date => isDate(date) ? (date.getTime() - unsetTime(date).getTime()) : 0;

export const durationToMS = ISODuration => {
	if (!isFullString(ISODuration)) {
		return ISODuration;
	}

	// pattern to match ISO 8601 Durations
	// https://en.wikipedia.org/wiki/ISO_8601#Durations
	// in the spec the order of designators doesnt' matter except for the `T` designator
	// in this implementation designators should come in order from large (`Y`) to small (`S`)
	const pattern = /^P(?!$)(((\d+)Y)?((\d+)M)?((\d+)D)?(T((\d+)H)?((\d+)M)?((\d+)S)?)?)$/;

	const matches = ISODuration.match(pattern);

	const diff = {
		years: parseInt(matches[3], 10) || 0,
		months: parseInt(matches[5], 10) || 0,
		days: parseInt(matches[7], 10) || 0,
		hours: parseInt(matches[10], 10) || 0,
		minutes: parseInt(matches[12], 10) || 0,
		seconds: parseInt(matches[14], 10) || 0
	};

	const compareDate = new Date();
	compareDate.setMilliseconds(0);
	const newDate = new Date(
		compareDate.getFullYear() + diff.years,
		compareDate.getMonth() + diff.months,
		compareDate.getDate() + diff.days,
		compareDate.getHours() + diff.hours,
		compareDate.getMinutes() + diff.minutes,
		compareDate.getSeconds() + diff.seconds,
		0
	);

	return (newDate - compareDate);
};

export const getTimes = () => {
	const date = new Date();
	date.setHours(0);
	date.setMinutes(0);
	const times = [];
	while (date.getHours() < 23 || date.getMinutes() < 59) {
		const hour = date.getHours();
		const minute = date.getMinutes();
		times.push(`${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`);

		if (hour < 23 || minute < 45) {
			date.setMinutes(date.getMinutes() + 15);
		} else {
			break;
		}
	}
	return times;
};
export const getLocalTime = (hour = 8, minutes = 0, date = Date.now()) => (modifyDate(new Date(date), { hours: hour, minutes: minutes }));

export const getUTCTime = (hour = 8, minutes = 0, date = Date.now()) => {
	const newDate = new Date(date);
	return new Date(Date.UTC(
		newDate.getFullYear(),
		newDate.getMonth(),
		newDate.getDate(),
		parseInt(hour),
		parseInt(minutes)
	));
};


export const localToUTCTime = (date = Date.now()) => {
	//TODO: change the naming of this function to UTCToLocalTime, which is more accurate with it's functionality.
	const newDate = new Date(date);
	return new Date(Date.UTC(
		newDate.getFullYear(),
		newDate.getMonth(),
		newDate.getDate(),
		newDate.getHours(),
		newDate.getMinutes()
	));
};

export const changeDateTimeToUTCTime = (date = new Date()) => {
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	);
};

export const parseTime = (value, date = Date.now()) => {
	const time = value.split(':').reduce((acc, curr, index) => ({
		...acc,
		[index === 0 ? 'hours' : 'minutes']: (curr.charAt(0) === '0' ? parseInt(curr.charAt(1), 10) : parseInt(curr, 10))
	}), {});

	return modifyDate(new Date(date), { hours: time.hours, minutes: time.minutes });
};

export const changeZone = (value, date = Date.now()) => {
	const time = value.split('T')[1].split(':').reduce((acc, curr, index) => index < 2 ? ({
		...acc,
		[index === 0 ? 'hours' : 'minutes']: (curr.charAt(0) === '0' ? parseInt(curr.charAt(1), 10) : parseInt(curr, 10))
	}) : { ...acc }, {});

	return modifyDate(new Date(date), { hours: time.hours, minutes: time.minutes });
};

export const stringifyTime = date => {
	if (isDate(date)) {
		const hours = date.getHours();
		const minutes = date.getMinutes();
		return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
	}
	return date;
};

export const timeWithinEventPeriod = (timeToCheck, dateToCheck, startTime, endTime) => {
	//if dateToCheck === startTimeDate === endTimeDate
	if (sameDates(unsetTime(dateToCheck), unsetTime(startTime)) && sameDates(unsetTime(dateToCheck), unsetTime(endTime))) {
		return timeToCheck.hours >= startTime.getHours() && (timeToCheck.hours === startTime.getHours() ? timeToCheck.minutes >= startTime.getMinutes() : true) && timeToCheck.hours <= endTime.getHours() && (timeToCheck.hours === endTime.getHours() ? timeToCheck.minutes <= endTime.getMinutes() : true);
	} else if (isLaterThan(unsetTime(dateToCheck), unsetTime(startTime)) && isEarlierThan(unsetTime(dateToCheck), unsetTime(endTime))) {
		return true;
	} else if (sameDates(unsetTime(dateToCheck), unsetTime(startTime))) {
		return timeToCheck.hours >= startTime.getHours() && (timeToCheck.hours === startTime.getHours() ? timeToCheck.minutes >= startTime.getMinutes() : true);
	} else if (sameDates(unsetTime(dateToCheck), unsetTime(endTime))) {
		return timeToCheck.hours <= endTime.getHours() && (timeToCheck.hours === endTime.getHours() ? timeToCheck.minutes <= endTime.getMinutes() : true);
	}
};

export const setWeekDay = (date, weekday, extraDays = 0) => {
	if (isDate(date) && isInteger(weekday)) {
		const newDate = new Date(date);

		const daysToAdd = weekday - date.getDay();
		newDate.setDate(date.getDate() + daysToAdd + extraDays);

		return newDate;
	}

	return date;
};

export const weekdays = [
	{ id: 1, index: 3, name: 'Monday', value: 'monday' },
	{ id: 2, index: 4, name: 'Tuesday', value: 'tuesday' },
	{ id: 3, index: 5, name: 'Wednesday', value: 'wednesday' },
	{ id: 4, index: 6, name: 'Thursday', value: 'thursday' },
	{ id: 5, index: 7, name: 'Friday', value: 'friday' }
];

export const weekend = [
	{ id: 6, index: 8, name: 'Saturday', value: 'saturday' },
	{ id: 0, index: 9, name: 'Sunday', value: 'sunday' }
];

export const weekOptions = [
	{ id: 7, index: 0, name: 'All week', value: 'allWeek' },
	{ id: 8, index: 1, name: 'Weekdays', value: 'weekdays' },
	{ id: 9, index: 2, name: 'Weekend', value: 'weekend' },
	...weekdays, ...weekend
];

// export const timeSlots = (Array.from(new Array(24 * 2)).map((time, index) => (
//   `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`
// )));

export const timeSlots = [...(Array.from(new Array(24 * 2)).map((time, index) => (
	`${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`
))), ...['23:59']];