import palette from './palette';
export default {
	fontFamily: [
		'Poppins',
		'sans-serif'
	].join(', '),
	body1: {
		color: palette.text.primary,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '21px'
	},
	body2: {
		color: palette.text.secondary,
		fontWeight: 500,
		fontSize: '12px',
		letterSpacing: '-0.04px',
		lineHeight: 2
	},
	body3: {
		color: palette.text.primary,
		fontSize: '12px',
		letterSpacing: '-0.04px',
		lineHeight: '18px'
	},
};