import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from '../layouts/Auth';
import DashboardLayout from '../layouts/Dashboard';

export const routes = isAuthenticated => {
	return [
		{
			path: '/',
			exact: true,
			component: () => <Redirect to="/planboard" />
		},
		{
			path: '/password',
			component: AuthLayout,
			routes: [
				{
					path: '/password/reset',
					exact: true,
					component: lazy(() => import('../views/IdentityServerFlows/Password'))
				}
			]
		},
		{
			path: '/email',
			component: AuthLayout,
			routes: [
				{
					path: '/email/confirm',
					exact: true,
					component: lazy(() => import('../views/IdentityServerFlows/ConfirmEmail'))
				}
			]
		},
		{
			path: '/auth',
			component: AuthLayout,
			routes: [
				{
					path: '/auth/login',
					exact: true,
					component: isAuthenticated ? () => <Redirect to="/planboard" /> : lazy(() => import('../views/UnderConstruction'))
				},
				{
					path: '/auth/callback',
					exact: true,
					component: lazy(() => import('../views/IdentityServerFlows/Callback'))
				},
				{
					path: '/auth/silent',
					exact: true,
					component: lazy(() => import('../views/IdentityServerFlows/SilentRenew'))
				},
			]
		},
		{
			route: '*',
			component: DashboardLayout,
			routes: [
				{
					path: '/bookings',
					exact: true,
					component: isAuthenticated ? lazy(() => import('../views/BookingManagement')) : () => <Redirect to="/auth/login" />,
				},
				{
					path: '/planboard',
					exact: true,
					component: isAuthenticated ? lazy(() => import('../views/Planboard')) : () => <Redirect to="/auth/login" />,
				},
				{
					path: '/trips',
					exact: true,
					component: isAuthenticated ? lazy(() => import('../views/Trips')) : () => <Redirect to="/auth/login" />,
				},
				{
					path: '/qr',
					exact: true,
					component: isAuthenticated ? lazy(() => import('../views/QRCodeRedirect')) : () => <Redirect to="/auth/login" />
				},
				{
					path: '/booking/booking-detail/:id/:tab',
					exact: true,
					component: isAuthenticated ? lazy(() => import('../views/BookingManagement/BookingDetails')) : () => <Redirect to="/auth/login" />
				},
				{
					path: '/trips/:tripId',
					exact: true,
					component: isAuthenticated ? lazy(() => import('../views/Trips/TripDetails')) : () => <Redirect to="/auth/login" />
				},
				{
					component: () => <Redirect to="/" />
				}
			]
		},
	];
};