import { planboardStates } from '../states';
import { FETCH_PLANBOARD_ITEMS_START, FETCH_PLANBOARD_ITEMS_SUCCESS, FETCH_PLANBOARD_ITEMS_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isEmptyArray, isArray, isFullArray, isFunction } from '../../shared/utility';
import { unsetTime, modifyDate } from '../../shared/datetime';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const PagedStatesCounter = new Counter(planboardStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchPlanboardItemsStart = identifier => {
	return {
		type: FETCH_PLANBOARD_ITEMS_START,
		identifier: identifier,
	};
};

export const fetchPlanboardItemsSuccess = (identifier, data = {}, concat = false) => {
	PagedStatesCounter.reset(identifier);
	return {
		type: FETCH_PLANBOARD_ITEMS_SUCCESS,
		identifier: identifier,
		data: data,
		concat: concat,
	};
};

export const fetchPlanboardItemsFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
	PagedStatesCounter.reset(identifier);
	return {
		type: FETCH_PLANBOARD_ITEMS_FAIL,
		identifier: identifier,
		error: error,
	};
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
** * * * * * * * * * * * * * * */
const fetchPlanboardItems = (identifier, path, settings = {}) => {
	const current = PagedStatesCounter.increment(identifier);
	const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
	return async dispatch => {
		dispatch(fetchPlanboardItemsStart(identifier));
		try {
			let data = await (
				isArray(path) ?
					Promise.all(path.map(p => events[method](p, bodyData))) :
					events[method](path, bodyData)
			);

			if (PagedStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(transformer)) {
					data = transformer(data);
				}
				dispatch(fetchPlanboardItemsSuccess(identifier, data, !!concat));
			}
		} catch (error) {
			if (PagedStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(errorCallback)) {
					errorCallback(dispatch, error);
				} else {
					dispatch(fetchPlanboardItemsFail(identifier, error));
				}
			}
		}
	};
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * * * *
 * BOOKING METHODS *
 * * * * * * * * * */
export const fetchPlanboardBookings = (page = { number: 1, size: 20 }, filters = {}, concat = true) => {
	const filtersStr = isFullArray(Object.keys(filters)) ? (
		Object.entries(filters).reduce((arr, map) => (
			// flatten filterproperties is passed in array
			isEmptyArray(map[1]) ? (
				arr
			) : isFullArray(map[1]) ? (
				[...arr, ...map[1].map(value => [map[0], value])]
			) : [...arr, ...[map]]
		), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	) : '';

	const filterString = '&' + [filtersStr].filter(str => str.length).join('&');

	return fetchPlanboardItems(
		'planboardBookings',
		`planboard?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};

export const fetchPlanboardItemsToAdd = (page = { number: 1, size: 20 }, filters = {}, concat = true) => {
	const fullFilters = {
		...filters,
		dateAfter: unsetTime(new Date()).toISOString(),
		dateBefore: modifyDate(unsetTime(new Date()), { date: '+1' }).toISOString()
	};
	const filtersStr = isFullArray(Object.keys(fullFilters)) ? (
		Object.entries(fullFilters).reduce((arr, map) => (
			// flatten filterproperties is passed in array
			isEmptyArray(map[1]) ? (
				arr
			) : isFullArray(map[1]) ? (
				[...arr, ...map[1].map(value => [map[0], value])]
			) : [...arr, ...[map]]
		), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	) : '';

	const filterString = '&' + [filtersStr].filter(str => str.length).join('&');

	return fetchPlanboardItems(
		'planboardItemsToAdd',
		`planboard?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? (page.number > 1) : concat }
	);
};
