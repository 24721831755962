import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { isObject } from '../../../shared/utility';

import { MyTooltip } from './style';

const IconInfoLabel = props => {

	const { content, label, infoTitle, icon, showIcon } = props;

	return (
		<Box alignItems='center' display='flex'>
			<Box pr={0.8}>
				{isObject(content) ? (content) : <Typography>{label}</Typography>}
			</Box>
			{showIcon ? (
				<MyTooltip
					arrow
					placement='top'
					title={<Typography>{infoTitle}</Typography>}
				>
					{icon}
				</MyTooltip>
			) : null}

		</Box>
	);
};

IconInfoLabel.propTypes = {
	content: PropTypes.object,
	label: PropTypes.string,
	infoTitle: PropTypes.string,
	icon: PropTypes.object,
	showIcon: PropTypes.bool
};

export default IconInfoLabel;
