import PropTypes from 'prop-types';

import { Button } from '@mui/material';

const StyledButton = props => {
	const { children, variant, ...rest } = props;

	//override variant and color props when variant variable is present
	const buttonProps = {
		...rest,
		...(variant === 'contained-primary' && {
			variant: 'contained',
			color: 'primary'
		}),
		...(variant === 'contained-secondary' && {
			variant: 'contained',
			color: 'secondary'
		}),
		...(variant === 'contained-tertiary' && {
			variant: 'outlined',
			color: 'primary'
		}),
		...(variant === 'contained-delete' && {
			variant: 'outlined',
			color: 'secondary'
		}),
		...(variant === 'inline-default') && {
			variant: 'text',
			color: 'primary'
		},
		...(variant === 'inline-delete') && {
			variant: 'text',
			color: 'secondary'
		}
	};

	return (
		<Button
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

StyledButton.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
	variant: PropTypes.oneOf(['contained-primary', 'contained-secondary', 'contained-tertiary', 'contained-delete', 'inline-default', 'inline-delete'])
};

export default StyledButton;