import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {},
	content: {
		padding: 0
	},
	header: {
		padding: theme.spacing(2)
	},
	pulse: {
		animation: '$pulse 1.5s ease-in-out 0.5s infinite'
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'50%': {
			opacity: 0.8,
		},
		'100%': {
			opacity: 1,
		},
	},
}));