import listReducer from './list';
import pagedReducer from './paged';
import filtersReducer from './filters';
import globalReducer from './global';
import detailsReducer from './details';
import conditionReducer from './condition';
import planboardReducer from './planboard';
import { reducer as oidcReducer } from 'redux-oidc';
import blobReducer from './blob';

// combine Redux reducers into one reducer to access Redux states accross the project
const rootReducer = {
	list: listReducer,
	paged: pagedReducer,
	filters: filtersReducer,
	global: globalReducer,
	details: detailsReducer,
	condition: conditionReducer,
	planboard: planboardReducer,
	oidc: oidcReducer,
	blob: blobReducer,
};

export default rootReducer;