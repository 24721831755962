import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {},
	content: {
		padding: 0
	},
	header: {
		padding: theme.spacing(2)
	},
	pulse: {
		animation: '$pulse 1.5s ease-in-out 0.5s infinite'
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'50%': {
			opacity: 0.8,
		},
		'100%': {
			opacity: 1,
		},
	},
	wave: {
		position: 'relative',
		backgroundColor: 'lightgrey',
		overflow: 'hidden',
		'&::after': {
			animation: '$loadingAnimation 1920ms linear infinite',
			background: 'linear-gradient(to right, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 90%)',
			content: '""',
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			top: 0,
			zIndex: 3
		},
	},
	'@keyframes loadingAnimation': {
		from: {
			transform: 'translate3d(-100%, 0, 0)',
		},
		to: {
			transform: 'translate3d(100%, 0, 0)',
		}
	}
}));