const white = '#FFFFFF';
const black = '#000000';
const lightGrey = '#F1F3F7';

export default {
	black,
	white,
	lightGrey,
	primary: {
		contrastText: '#FFFFFF',
		// dark: colors.indigo[900],
		main: '#15263E', //primary blue, pacific (secondary button color)
		light: '#5B6778' //disabled blue
	},
	secondary: {
		contrastText: '#15263E',
		//dark: colors.blue[900],
		main: '#E4EEFB', //secondary blue
		//light: '#5B6778' //disabled blue
	},
	error: {
		contrastText: '#FFFFFF',
		dark: '#9C1F2E', //dark red
		main: '#CE2A3D', //red
		light: '#FFF7EF' //light red
	},
	info: {
		main: '#15263E', //primary blue
		light: '#E4EEFB' //light blue
	},
	success: {
		main: '#285A47', //dark green
		light: '#CFF5E7' //light green
	},
	warning: {
		main: '#F57C00', //dark orange
		light: '#FFF7EF' //light orange
	},
	disabled: {
		main: '#586B85', //dark grey
		light: '#EEE' //light grey
	},
	text: {
		primary: '#15263E', //primary blue (main text color)
		secondary: '#586B85', //body text color
		link: '#15263E' //primary blue (main text color)
	},
	button: {
		contrastText: {
			primary: '#FFFFFF',
			primaryDisabled: '#FFFFFF',
			secondary: '#FFFFFF',
			secondaryDisabled: '#FFFFFF'
		},
		primary: '#016DFF', //brandeis blue
		primaryDisabled: '#4192FF', //blue de france
		secondary: '#15263E', //pacific
		secondaryDisabled: '#5B6778',
		outline: '#EDEDF2', //light grey,
		deleteColor: '#CE2A3D', //red
		deleteColorDisabled: '#DB606E' //light red
	},
	link: '#15263E', //primary blue (main text color)
	icon: '#15263E', //primary blue (main text color)
	background: {
		default: '#FFFFFF',
		paper: '#FFFFFF'
	},
	inherit: {
		contrastText: '#c24747',
		main: '#c24747'
	},
	divider: '#E4E7EB', //divider color (grey)
	neutral: {
		main: '#016dff',
	},
};