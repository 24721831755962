import { createTheme } from '@mui/material';
import palette from './palette';
import typography from './typography';
import link from './link';
import overrides from './overrides';

const theme = createTheme({
	typography,
	components: {
		...overrides,
	},
	palette,
	link
});


export default theme;