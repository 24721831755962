import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStylesMyTooltip = makeStyles(theme => ({
	arrow: {
		color: theme.palette.common.white,
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.15)',
	},
}));

export function MyTooltip(props) {
	const classes = useStylesMyTooltip();
	return <Tooltip arrow classes={classes} {...props} />;
}