import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		height: 0,
		paddingTop: '43%',
		position: 'relative'
	},
	map: {
		display: 'block',
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		borderRadius: 5,
		position: 'absolute',
		top: 0,
		left: 0
	}
}));