import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			color: palette.text.primary,
		}
	},
	defaultProps: {
		underline: 'hover',
	}
};
