import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useStyles } from './style';
import moment from 'moment';
import 'moment/locale/nl';

import { Box } from '@mui/material';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { translate } from '../../../shared/utility';

const DatePicker = props => {
	const { start, end, handleDatesChange, focused, handleFocusChange, paddingTop, paddingBottom, language } = props;
	const classes = useStyles();

	if (language !== 'en') {
		moment.locale(language);
	}
	return (
		<div className={classes.root}>
			<Box alignItems='center' display='flex' flexDirection='row' pb={paddingBottom} pt={paddingTop}>
				<DateRangePicker
					displayFormat={() => 'DD/MM/YYYY'}
					endDate={end}
					endDateId='idEndDate'
					endDatePlaceholderText={translate('ui.label.endDate', language)}
					focusedInput={focused}
					isOutsideRange={() => false}
					onDatesChange={handleDatesChange}
					onFocusChange={handleFocusChange}
					showClearDates={true}
					showDefaultInputIcon={true}
					startDate={start}
					startDateId='idStartDate'
					startDatePlaceholderText={translate('ui.label.startDate', language)}
				/>
			</Box>
		</div>

	);
};

DatePicker.propTypes = {
	start: PropTypes.object,
	end: PropTypes.object,
	handleDatesChange: PropTypes.func,
	focused: PropTypes.string,
	handleFocusChange: PropTypes.func,
	paddingTop: PropTypes.number,
	paddingBottom: PropTypes.number,
	language: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		language: state.global.language
	};
};

export default connect(mapStateToProps, null)(DatePicker);