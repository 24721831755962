import { makeStyles } from '@mui/styles';
import { colors } from '@mui/material';
export const useStyles = makeStyles(theme => ({
	root: {},
	userMenu: {
		width: 250,
		maxWidth: '90%',
	},
	userContainer: {
		padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		width: theme.spacing(9),
		height: theme.spacing(9),
		marginBottom: theme.spacing(3),
	},
	userDetailText: {
		'& + &': {
			marginTop: theme.spacing(.5)
		}
	},
	buttonContainer: {
		padding: theme.spacing(2, 3)
	},
	tipCard: {
		marginTop: '6px',
		backgroundColor: colors.indigo[50],
		boxShadow: 'none',
		padding: '12px',
		position: 'relative'
	},
}));