import palette from './palette';

export default {
	fontFamily: [
		'Poppins',
		'sans-serif'
	].join(', '),
	h1: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '35px',
		letterSpacing: '-0.24px',
		lineHeight: '40px'
	},
	h2: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '29px',
		letterSpacing: '-0.24px',
		lineHeight: '32px'
	},
	h3: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '24px',
		letterSpacing: '-0.06px',
		lineHeight: '28px'
	},
	h4: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '20px',
		letterSpacing: '-0.06px',
		lineHeight: '24px'
	},
	h5: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '16px',
		letterSpacing: '-0.05px',
		lineHeight: '20px'
	},
	h6: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '20px'
	},
	subtitle1: {
		color: palette.text.primary,
		fontSize: '16px',
		letterSpacing: '-0.05px',
		lineHeight: '25px'
	},
	subtitle2: {
		color: palette.text.primary,
		fontWeight: 400,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '21px'
	},
	body1: {
		color: palette.text.primary,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '21px'
	},
	body2: {
		color: palette.text.secondary,
		fontSize: '12px',
		letterSpacing: '-0.04px',
		lineHeight: '18px'
	},
	body3: {
		color: palette.text.primary,
		fontSize: '12px',
		letterSpacing: '-0.04px',
		lineHeight: '18px'
	},
	link1: {
		color: palette.text.primary,
		fontSize: '12px',
		fontWeight: 500,
		letterSpacing: '-0.04px',
		lineHeight: '18px'
	},
	button: {
		color: palette.text.primary,
		fontSize: '14px'
	},
	caption: {
		color: palette.text.primary,
		opacity: 0.9,
		fontSize: '11px',
		letterSpacing: '0.33px',
		lineHeight: '13px'
	},
	overline: {
		color: palette.text.primary,
		fontSize: '11px',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '13px',
		textTransform: 'uppercase'
	},
	label: {
		color: palette.text.secondary,
		fontSize: '11px',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '13px',
	},
	pagination: {
		color: palette.text.primary,
		fontSize: '11px',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '13px',
	},
	input: {
		color: palette.text.primary,
		fontSize: 12,
		fontWeight: 500,
		letterSpacing: '-0.04px',
		lineHeight: '18px'
	},
	helperText: {
		color: palette.text.secondary,
		fontSize: '11px',
		letterSpacing: '0.37px',
		lineHeight: '16px'
	},
	tooltip: {
		color: palette.text.primary,
		fontWeight: 400,
		fontSize: '12px',
		letterSpacing: '-0.04px',
		lineHeight: '18px'
	},
};
