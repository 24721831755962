import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { translate } from '../../../shared/utility';

import { TextField } from '@mui/material';

import { useStyles } from './style';

const FormFieldSelect = props => {
	const { className, label, name, variable, required, displayEmpty, helperText, disabled, hasDisplayFunction, language, children } = props;
	const classes = useStyles();

	const helperOrErrorText = () => {
		if (variable.isTouched && !variable.hasFocus && !variable.isValid) {
			//show error message
			return translate('ui.input.error.validValue', language);
		} else if (helperText) {
			return helperText;
		} else {
			return null;
		}
	};

	return (
		<TextField
			className={className}
			disabled={disabled}
			error={variable.isTouched && !variable.hasFocus && !variable.isValid}
			fullWidth
			helperText={helperOrErrorText()}
			label={label}
			name={name}
			required={required}
			select
			variant="outlined"
			{...variable.bindToFormField}
			FormHelperTextProps={{
				style: {
					position: 'absolute',
					marginTop: '4rem'
				}
			}}
			InputLabelProps={{
				shrink: true,
			}}
			SelectProps={{
				classes: {
					...(hasDisplayFunction && { icon: classes.hide })
				},
				displayEmpty: displayEmpty,
				MenuProps: {
					PaperProps: {
						style: {
							maxHeight: 250
						}
					}
				},
			}}
		>
			{children}
		</TextField>
	);
};

FormFieldSelect.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	variable: PropTypes.object,
	required: PropTypes.bool,
	displayEmpty: PropTypes.bool,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
	hasDisplayFunction: PropTypes.bool,
	language: PropTypes.string,
	children: PropTypes.node
};

const mapStateToProps = state => {
	return {
		// GLOBAL PROPS
		language: state.global.language
	};
};

export default connect(mapStateToProps)(FormFieldSelect);