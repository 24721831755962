import { detailsStates } from '../states';
import { FETCH_DETAILS_START, FETCH_DETAILS_SUCCESS, FETCH_DETAILS_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isInteger, isFullString, stringifyQueryParams, isNull, isUndefined, isFullArray, isEmptyArray } from '../../shared/utility';
import { localToUTCTime } from '../../shared/datetime';
import { isDate } from 'moment';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const DetailsStatesCounter = new Counter(detailsStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchDetailsStart = identifier => {
	return {
		type: FETCH_DETAILS_START,
		identifier: identifier,
	};
};

export const fetchDetailsSuccess = (identifier, data = null) => {
	DetailsStatesCounter.reset(identifier);
	return {
		type: FETCH_DETAILS_SUCCESS,
		identifier: identifier,
		data: data,
	};
};

export const fetchDetailsFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
	DetailsStatesCounter.reset(identifier);
	return {
		type: FETCH_DETAILS_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const resetState = identifier => {
	return dispatch => {
		dispatch(fetchDetailsSuccess(identifier));
	};
};

export const updateDetailsState = (identifier, data = null) => {
	return dispatch => {
		dispatch(fetchDetailsSuccess(identifier, data));
	};
};

const flattenFilters = filters => {
	const filtersStr = isFullArray(Object.keys(filters)) ? (
		Object.entries(filters).reduce((arr, map) => (
			// flatten filterproperties is passed in array
			isEmptyArray(map[1]) ? (
				arr
			) : isFullArray(map[1]) ? (
				[...arr, ...map[1].map(value => [map[0], value])]
			) : [...arr, ...[map]]
		), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	) : '';

	const filterString = '?' + [filtersStr].filter(str => str.length).join('&');

	return filterString;
};


/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * * *
 * USER METHODS  *
 * * * * * * * * */
export const fetchCurrentUser = () => {
	return async dispatch => {
		dispatch(fetchDetailsStart('currentUser'));
		try {
			const data = await events.get('users/me');
			dispatch(fetchDetailsSuccess('currentUser', data));

		} catch (error) {
			dispatch(fetchDetailsFail('currentUser', error));
		}
	};
};

export const fetchUser = (id, preventRedirect = false) => {
	return async dispatch => {
		dispatch(fetchDetailsStart('user'));
		try {
			const data = await events.get(`users/${id}`, preventRedirect);
			dispatch(fetchDetailsSuccess('user', data));
		} catch (error) {
			dispatch(fetchDetailsFail('user', error));
		}
	};
};

/* * * * * * * * * *
 * METHODS BOOKING *
 * * * * * * * * * */
export const addPlanboardBooking = (categoryId = null, itemInstanceId = null, userId = null, periodStart = null, periodEnd = null, billingType = null, comment = null, discount = null, licensePlateNumber = null) => {
	const bodyData = isInteger(categoryId) && isInteger(itemInstanceId) && isInteger(userId) && isDate(periodStart) && isDate(periodEnd) && isFullString(billingType) ? {
		categoryId,
		itemInstanceId,
		userId,
		periodStart,
		periodEnd,
		billingType,
		comment,
		discount,
		licensePlateNumber
	} : null;

	return async dispatch => {
		dispatch(fetchDetailsStart('addedPlanboardBooking'));
		try {
			const data = await events.post('planboard', bodyData, true);
			dispatch(fetchDetailsSuccess('addedPlanboardBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedPlanboardBooking', error));
		}
	};
};

export const fetchPlanboardItemDetails = (itemId = null, instanceId = null) => {
	return async dispatch => {
		dispatch(fetchDetailsStart('planboardItemDetails'));
		try {
			const data = await events.get(`planboard/items/${itemId}/iteminstances/${instanceId}`);
			dispatch(fetchDetailsSuccess('planboardItemDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('planboardItemDetails', error));
		}
	};
};

export const planboardBooking = (bookingId = null) => {
	return async dispatch => {
		dispatch(fetchDetailsStart('planboardBooking'));
		try {
			const data = await events.get(`planboard/bookings/${bookingId}`);
			dispatch(fetchDetailsSuccess('planboardBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('planboardBooking', error));
		}
	};
};

export const startBooking = (id = null, kilometres = null) => {
	const bodyData = isFullString(kilometres) ? {
		currentKilometres: parseInt(kilometres, 10)
	} : null;

	return async dispatch => {
		dispatch(fetchDetailsStart('startBooking'));
		try {
			const data = await events.put(`bookings/${id}/start`, bodyData);
			dispatch(fetchDetailsSuccess('startBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('startBooking', error));
		}
	};
};

export const stopBooking = (id = null, kilometres = null) => {

	const bodyData = isFullString(kilometres) ? {
		currentKilometres: parseInt(kilometres, 10)
	} : null;

	return async dispatch => {
		dispatch(fetchDetailsStart('stopBooking'));
		try {
			const data = await events.put(`bookings/${id}/stop`, bodyData);
			dispatch(fetchDetailsSuccess('stopBooking', data));
			dispatch(fetchDetailsSuccess('fetchBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('stopBooking', error));
		}
	};
};

export const validateEditedBookingCategory = (bookingId = null, periodStart = null, periodEnd = null, userId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'categoryUnique',
		...(!isNull(periodStart) && { startDate: periodStart.toISOString() }),
		endDate: periodEnd.toISOString(),
		...(!isNull(userId) && { userId })
	});

	return async dispatch => {
		dispatch(fetchDetailsStart('validatedEditedBookingCategory'));
		try {
			const data = await events.get(`bookings/${bookingId}/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedEditedBookingCategory', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedEditedBookingCategory', error));
		}
	};
};

export const validateBookingCategory = (userId = null, categoryId = null, periodStart = null, periodEnd = null) => {
	const queryParams = stringifyQueryParams({
		type: 'bookingAllowed',
		userId,
		periodStart,
		periodEnd
	});

	return async dispatch => {
		dispatch(fetchDetailsStart('validatedBookingCategory'));
		try {
			const data = await events.get(`categories/${categoryId}/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedBookingCategory', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedBookingCategory', error));
		}
	};
};

export const fetchBookingPrice = (bookingId = null, startDate = null, endDate = null, discount = null) => {
	const queryParams = stringifyQueryParams({
		...(isDate(startDate) && { startDate: localToUTCTime(startDate).toISOString() }),
		endDate: localToUTCTime(endDate).toISOString(),
		// ...(isDate(startDate) && { startDate: startDate.toISOString() }),
		// endDate: endDate.toISOString(),
		...(!isNull(discount) && { discount })
	});
	return async dispatch => {
		dispatch(fetchDetailsStart('bookingPrice'));
		try {
			const data = await events.get(`bookings/${bookingId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('bookingPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('bookingPrice', error));
		}
	};
};

export const fetchCalculatedPrice = (itemId = null, periodStart = null, periodEnd = null, itemInstanceId = null, discount = null, promoCode = null) => {
	const queryParams = stringifyQueryParams({
		...(!isNull(itemInstanceId) && !isUndefined(itemInstanceId) && { itemInstanceId }),
		...(isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		periodEnd: periodEnd.toISOString(),
		...(!isNull(discount) && { discount }),
		...(isFullString(promoCode) && { promoCode })
	});

	return async dispatch => {
		dispatch(fetchDetailsStart('calculatedPrice'));
		try {
			const data = await events.get(`items/${itemId}/calculatedprice${queryParams}`);
			dispatch(fetchDetailsSuccess('calculatedPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('calculatedPrice', error));
		}
	};
};

export const fetchBookingAvailability = (bookingId = null, startDate = null, endDate = null) => {
	const queryParams = stringifyQueryParams({
		...(isDate(startDate) && { startDate: startDate.toISOString() }),
		endDate: endDate.toISOString(),
		availabilityValidation: true
	});
	return async dispatch => {
		dispatch(fetchDetailsStart('bookingAvailability'));
		try {
			const data = await events.get(`bookings/${bookingId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('bookingAvailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('bookingAvailability', error));
		}
	};
};

export const fetchItemInstanceAvailability = (itemId = null, itemInstanceId = null, periodStart = null, periodEnd = null) => {
	const queryParams = stringifyQueryParams({
		...(!isNull(itemInstanceId) && { itemInstanceId }),
		...(isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		periodEnd: periodEnd.toISOString()
	});
	return async dispatch => {
		dispatch(fetchDetailsStart('itemInstanceAvailability'));
		try {
			const data = await events.get(`items/${itemId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('itemInstanceAvailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemInstanceAvailability', error));
		}
	};
};

export const fetchItemPrice = (itemId = null, periodStart = null, periodEnd = null, itemInstanceId = null) => {
	const queryParams = stringifyQueryParams({
		...(!isNull(itemInstanceId) && { itemInstanceId }),
		...(isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		periodEnd: periodEnd.toISOString()
	});
	return async dispatch => {
		dispatch(fetchDetailsStart('itemPrice'));
		try {
			const data = await events.get(`items/${itemId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('itemPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemPrice', error));
		}
	};
};

/* * * * * * * * * * 
 * Ticket METHODS  *
 * * * * * * * * * */
export const addTicket = (itemInstanceId = null, description = null, ticketDefectId = null, serviceOrganisationId = null) => {
	const bodyData = isInteger(itemInstanceId) && isInteger(ticketDefectId) ? new FormData() : null;

	if (!isNull(bodyData)) {
		bodyData.append('itemInstanceId', itemInstanceId);
		bodyData.append('ticketDefectId', ticketDefectId);
		if (isInteger(serviceOrganisationId)) {
			bodyData.append('serviceOrganisationId', serviceOrganisationId);
		}
		if (isFullString(description)) {
			bodyData.append('description', description);
		}
	}

	return async dispatch => {
		dispatch(fetchDetailsStart('addedTicket'));
		try {
			const data = await events.post('tickets', bodyData, true, true);
			dispatch(fetchDetailsSuccess('addedTicket', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedTicket', error));
		}
	};
};

/* * * * * * * * * *
 * PAYMENT METHODS *
 * * * * * * * * * */
export const fetchPaymentMethodsOfUser = (userId = null) => {
	const queryParams = stringifyQueryParams({
		userId
	});

	return async dispatch => {
		dispatch(fetchDetailsStart('paymentMethodsUser'));
		try {
			const data = await events.get(`paymentmethods${queryParams}`);
			dispatch(fetchDetailsSuccess('paymentMethodsUser', data));
		} catch (error) {
			dispatch(fetchDetailsFail('paymentMethodsUser', error));
		}
	};
};

/* * * * * * * * * * *
 * INSTANCE METHODS  *
 * * * * * * * * * * */
export const fetchInstance = (itemId = null, itemInstanceId = null) => {
	return async dispatch => {
		dispatch(fetchDetailsStart('fetchInstance'));
		try {
			const data = await events.get(`items/${itemId}/iteminstances/${itemInstanceId}`);
			dispatch(fetchDetailsSuccess('fetchInstance', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchInstance', error));
		}
	};
};

/* * * * * * * * * *
* BOOKINGS  MODELS *
* * * * * * * * * */
export const fetchBooking = (id = null) => {

	return async dispatch => {
		dispatch(fetchDetailsStart('fetchBooking'));
		try {
			const data = await events.get(`bookings/${id}`);
			dispatch(fetchDetailsSuccess('fetchBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchBooking', error));
		}
	};
};

export const bookingTrip = (bookingId = null, tripId = null) => {

	return async dispatch => {
		dispatch(fetchDetailsStart('bookingTrip'));
		try {
			const data = await events.get(`bookings/${bookingId}/trips/${tripId}`);
			dispatch(fetchDetailsSuccess('bookingTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('bookingTrip', error));
		}
	};
};

export const trip = (tripId = null) => {

	return async dispatch => {
		dispatch(fetchDetailsStart('trip'));
		try {
			const data = await events.get(`trips/${tripId}`);
			dispatch(fetchDetailsSuccess('trip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('trip', error));
		}
	};
};

export const userInvitation = (inviteId = null) => {
	return async dispatch => {
		dispatch(fetchDetailsStart('userInvitation'));
		try {
			const data = await events.get(`invitations/${inviteId}`);
			dispatch(fetchDetailsSuccess('userInvitation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('userInvitation', error));
		}
	};
};

export const updateInvite = (inviteId = null, body = null,) => {
	return async dispatch => {
		dispatch(fetchDetailsStart('updateInvite'));
		try {
			const data = await events.put(`invitations/${inviteId}`, body);
			dispatch(fetchDetailsSuccess('updateInvite', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateInvite', error));
		}
	};
};

export const exportBookingTrip = (bookingId = null, filters = {}) => {

	const filterString = flattenFilters(filters);

	return async dispatch => {
		dispatch(fetchDetailsStart('exportBookingTrip'));
		try {
			const data = await events.get(`bookings/${bookingId}/trips/exports${filterString}`, false, false, true);
			dispatch(fetchDetailsSuccess('exportBookingTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportBookingTrip', error));
		}
	};
};

export const exportTrips = (filters = {}) => {

	const filterString = flattenFilters(filters);

	return async dispatch => {
		dispatch(fetchDetailsStart('exportTrips'));
		try {
			const data = await events.get(`trips/exports${filterString}`, false, false, true);
			dispatch(fetchDetailsSuccess('exportTrips', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportTrips', error));
		}
	};
};

export const exportTripDetails = (tripId = null, exportType = null) => {

	return async dispatch => {
		dispatch(fetchDetailsStart('exportTripDetails'));
		try {
			const data = await events.get(`trips/${tripId}/exports?exportType=${exportType}`, false, false, true);
			dispatch(fetchDetailsSuccess('exportTripDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportTripDetails', error));
		}
	};
};

export const exportBookingTripDetails = (bookingId = null, tripId = null, exportType = null) => {

	return async dispatch => {
		dispatch(fetchDetailsStart('exportBookingTripDetails'));
		try {
			const data = await events.get(`bookings/${bookingId}/trips/${tripId}/exports?exportType=${exportType}`, false, false, true);
			dispatch(fetchDetailsSuccess('exportBookingTripDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportBookingTripDetails', error));
		}
	};
};