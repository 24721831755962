import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { translate, isObject } from '../../../shared/utility';

import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CircularProgress,
	IconButton,
	Typography,
	Divider,
	Skeleton
} from '@mui/material';
import { StyledButton } from '../../../components';
import DialogTitleCustom from './DialogTitleCustom/DialogTitleCustom';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './style';

const AlertDialog = props => {
	const {
		className, open, handleClose, handleConfirm, dialogTitle, dialogDescription, hasOnlyConfirmButton, loading,
		language, textDialog, loadingState, furtherLoading, variantDescription, content, isValid, dialogContent,
		titleVariant, confirmDisabled
	} = props;

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<Dialog
				aria-describedby="alert-dialog-description"
				aria-labelledby="alert-dialog-title"
				classes={{ paper: classes.dialogContainer }}
				elevation={0}
				onClose={handleClose}
				open={open}
			>
				{!furtherLoading && loadingState ? (<Box ><Skeleton animation="wave" height={52} width={300} /></Box>) :
					<Box alignItems='center' className={classes.dialogTitle} display='flex' justifyContent='space-between'>
						{isObject(textDialog) && textDialog.notAllowed ?
							<DialogTitleCustom
								elevation={0}
								icon={<CloseIcon />}
								onClose={handleClose}
								title={textDialog.title}
							/> :
							<DialogTitle disableTypography id="alert-dialog-title"><Typography variant={titleVariant ? titleVariant : 'h2'}>{dialogTitle}</Typography></DialogTitle>
						}
						{hasOnlyConfirmButton ? (
							<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
								<CloseIcon />
							</IconButton>
						) : null}
					</Box>
				}
				{!furtherLoading && loadingState ? (<Box ><Skeleton animation="wave" height={145} width={300} /></Box>) :
					<>
						{dialogContent ?
							<DialogContent className={classes.dialog}>
								{dialogContent}
							</DialogContent>
							:
							<DialogContent className={classes.dialog} dividers={isObject(textDialog) && textDialog.notAllowed}>
								<DialogContentText id="alert-dialog-description">
									<Typography component='span' variant={variantDescription}>{isObject(textDialog) && textDialog.notAllowed ? textDialog.description : dialogDescription}</Typography>
								</DialogContentText>
							</DialogContent>}
						{content ? <div>{content}</div> : null}
						{isObject(textDialog) && textDialog.notAllowed ? <Box height='50px'></Box> : (
							<>
								<Divider className={classes.actionDivider} />
								<DialogActions className={clsx({
									[classes.actions]: true,
									[classes.spaceBetween]: !hasOnlyConfirmButton,
									[classes.alignRight]: hasOnlyConfirmButton
								})}>
									{!hasOnlyConfirmButton ? (
										<StyledButton
											onClick={handleClose}
											variant='contained-tertiary'
											className={classes.actionButton}
										// disabled={loading}
										>
											{translate('ui.button.contained.cancel', language)}
										</StyledButton>
									) : null}
									<StyledButton
										autoFocus
										className={classes.actionButton}
										disabled={confirmDisabled || (isValid ? !loading : loading)}
										onClick={handleConfirm}
										variant='contained-secondary'
									>
										{isValid ?
											translate('ui.button.contained.confirm', language)
											: loading ? <CircularProgress disableShrink size={24} /> : translate('ui.button.contained.confirm', language)}
									</StyledButton>
								</DialogActions>
							</>
						)}
					</>
				}
			</Dialog>
		</div>
	);
};

AlertDialog.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	confirmDisabled: PropTypes.bool,
	dialogTitle: PropTypes.string,
	dialogDescription: PropTypes.string,
	hasOnlyConfirmButton: PropTypes.bool,
	loading: PropTypes.bool,
	language: PropTypes.string,
	textDialog: PropTypes.object,
	furtherLoading: PropTypes.bool,
	loadingState: PropTypes.bool,
	variantDescription: PropTypes.string,
	content: PropTypes.object,
	dialogContent: PropTypes.node,
	titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	isValid: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		language: state.global.language
	};
};

export default withRouter(connect(mapStateToProps)(AlertDialog));