import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isFullString } from '../../../shared/utility';

import * as Images from './assets/index';

import { Typography, Paper, Box } from '@mui/material';
import { StyledButton } from '../../../components';

import { useStyles } from './style';

const VALID_IMAGES = Object.keys(Images);

const EmptyState = props => {
	const { title, subTitle, image, callToAction, callToActionText, className, handleAction } = props;
	const classes = useStyles();

	return (
		<Paper className={clsx(classes.root, className)}>
			<Box alignContent="center" display="flex" flexDirection="column" justifyContent="center">
				<Box display="flex" justifyContent="center">
					{VALID_IMAGES.includes(image) ? (
						<img
							alt={''}
							src={Images[image]}
						/>
					) : null}
				</Box>
				<Box alignItems="center" className={classes.textContainer} display="flex" flexDirection="column">
					<Typography className={classes.emptyStateTitle} variant="h6">{title}</Typography>
					{subTitle ? (
						<Typography className={classes.emptyStateSubtitle} component="p" variant="subtitle1">
							<small>{subTitle}</small>
						</Typography>
					) : null}
				</Box>
				<Box display="flex" justifyContent="center">
					{(callToAction || handleAction) && callToActionText ? (
						<StyledButton
							classes={classes.emptyStateButton}
							href={isFullString(callToAction) ? callToAction : null}
							onClick={handleAction}
							size="large"
							variant="contained-secondary"
						>
							{callToActionText}
						</StyledButton>
					) : null}
				</Box>
			</Box>
		</Paper>
	);
};

EmptyState.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	image: PropTypes.oneOf(VALID_IMAGES),
	callToAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	callToActionText: PropTypes.string,
	className: PropTypes.string,
	handleAction: PropTypes.func
};

export default EmptyState;