import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import { translate } from '../../../shared/utility';

import { TextField, Typography, InputAdornment } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB, nl } from 'date-fns/locale';

import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { useStyles } from './style';

const ReactDatePicker = props => {
	const { containerClassName, error, variant, label, disabled, language, ...rest } = props;
	const classes = useStyles();
	const [showErrorState, setShowErrorState] = useState(false);

	registerLocale('nl', nl);
	registerLocale('en', enGB);

	useEffect(() => {
		if (disabled) {
			setShowErrorState(false);
		} else {
			setShowErrorState(error);
		}
	}, [error, disabled]);

	const CustomTextInput = forwardRef(({ value, onClick }, ref) => ( //eslint-disable-line
		<Typography
			className={classes.date}
			component='p'
			onClick={onClick}
			ref={ref}
			variant='h5'
		>
			{value}
			<ArrowDownIcon />
		</Typography>
	));

	return (
		<div className={containerClassName}>
			<DatePicker
				{...rest}
				calendarStartDay={1}
				customInput={
					variant === 'input' ? (
						<TextField
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<CalendarTodayIcon />
									</InputAdornment>
								)
							}}
							error={showErrorState}
							fullWidth
							label={label}
							variant='outlined'
						/>
					) : (
						<CustomTextInput />
					)
				}
				disabled={disabled}
				locale={language}
				popperClassName={classes.popper}
				popperModifiers={[
					{
						name: 'preventOverflow',
						options: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport'
						}
					}
				]}
				timeCaption={translate('ui.time', language)}
			/>
		</div>
	);
};

ReactDatePicker.propTypes = {
	containerClassName: PropTypes.string,
	error: PropTypes.bool,
	variant: PropTypes.oneOf(['input', 'text']),
	label: PropTypes.string,
	disabled: PropTypes.bool,
	language: PropTypes.string
};

ReactDatePicker.defaultProps = {
	dateFormat: 'd MMMM, H:mm',
	timeFormat: 'HH:mm',
	timeIntervals: 15,
	variant: 'input'
};

const mapStateToProps = state => {
	return {
		language: state.global.language
	};
};

export default connect(mapStateToProps, null)(ReactDatePicker);