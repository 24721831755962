import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 550
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2, 3, 2, 3)
	},
	dialogContainer: {
		minHeight: '25vh',

	},
	closeButton: {
		marginRight: '10px'
	},
	button: {
		marginLeft: '10px'
	},
}));