import palette from '../palette';
import typography from '../typography';

export default {
	styleOverrides: {
		root: {
			...typography.body3,
			borderBottom: `1px solid ${palette.divider}`
		},
		head: {
			...typography.h6
		}
	}
};
