import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {},
	dialogContainer: {
		width: '90%'
	},
	mainActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start'
	},
	notice: {
		marginTop: theme.spacing(1)
	},
	deleteButton: {
		marginTop: theme.spacing(1),
		color: theme.palette.white,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	},
	buttonIcon: {
		marginRight: theme.spacing(1)
	},
	dialog: {
		padding: theme.spacing(1, 4)
	},
	dialogActions: {
		padding: theme.spacing(2, 2, 2, 2),
		boxSizing: 'border-box',
	},
	actionButton: {
		width: '47%',
		minWidth: 200,
		minHeight: 50,
		margin: theme.spacing(1)
	},
	closeButton: {
		marginRight: theme.spacing(1.5)
	},
	actions: {
		padding: theme.spacing(3, 4),
		display: 'flex',
		flexWrap: 'wrap-reverse',
	},
	spaceBetween: {
		justifyContent: 'space-between'
	},
	alignRight: {
		justifyContent: 'flex-end'
	},
	actionDivider: {
		margin: theme.spacing(0, 4)
	},
	dialogTitle: {
		padding: theme.spacing(3, 1, 0)
	}
}));


