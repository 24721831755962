import typography from '../typography';

export default {
	styleOverrides: {
		caption: {
			...typography.pagination
		},
		input: {
			...typography.pagination
		}
	}
};