import PropTypes from 'prop-types';
import clsx from 'clsx';
import palette from '../../../theme/palette';

import { Typography } from '@mui/material';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';

const Label = props => {
	const { className, variant, color, shape, children, style, fontColor, type, ...rest } = props;

	const classes = useStyles();

	const rootClassName = clsx(
		{
			[classes.root]: true,
			[classes.rounded]: shape === 'rounded'
		},
		className
	);

	const colorVariants = {
		success: {
			backgroundColor: palette.success.light,
			color: palette.success.main
		},
		warning: {
			backgroundColor: palette.warning.light,
			color: palette.warning.main
		},
		error: {
			backgroundColor: palette.error.light,
			color: palette.error.main
		},
		default: {
			backgroundColor: palette.info.light,
			color: palette.info.main
		},
		disabled: {
			backgroundColor: palette.disabled.light,
			color: palette.disabled.main
		},
	};

	const finalStyle = { ...style };

	if (variant === 'contained') {
		finalStyle.backgroundColor = isFullString(color) ? color : isFullString(type) ? colorVariants[type].backgroundColor : colorVariants.default.backgroundColor;
		finalStyle.color = isFullString(fontColor) ? fontColor : isFullString(type) ? colorVariants[type].color : colorVariants.default.color;
	} else {
		finalStyle.border = `1px solid ${isFullString(color) ? color : isFullString(type) ? colorVariants[type].color : colorVariants.default.color}`;
		finalStyle.color = isFullString(color) ? color : isFullString(type) ? colorVariants[type].color : colorVariants.default.color;
	}

	return (
		<Typography
			{...rest}
			className={rootClassName}
			style={finalStyle}
		>
			{children}
		</Typography>
	);
};

Label.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.string,
	shape: PropTypes.oneOf(['square', 'rounded']),
	style: PropTypes.object,
	variant: PropTypes.oneOf(['contained', 'outlined']),
	fontColor: PropTypes.string,
	type: PropTypes.oneOf(['success', 'warning', 'error', 'default', 'disabled'])
};

Label.defaultProps = {
	style: {},
	variant: 'contained',
	shape: 'rounded',
	type: 'default'
};

export default Label;
