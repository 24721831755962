import { listStates } from '../states';
import { FETCH_LIST_START, FETCH_LIST_SUCCESS, FETCH_LIST_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isArray, isFunction, isFullArray, isEmptyArray, isEmptyString, isFullString, isEmptyObject } from '../../shared/utility';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const ListStatesCounter = new Counter(listStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchListStart = identifier => {
	return {
		type: FETCH_LIST_START,
		identifier: identifier,
	};
};

export const fetchListSuccess = (identifier, data = [], concat = false) => {
	ListStatesCounter.reset(identifier);
	return {
		type: FETCH_LIST_SUCCESS,
		identifier: identifier,
		data: data,
		concat: concat,
	};
};

export const fetchListFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
	ListStatesCounter.reset(identifier);
	return {
		type: FETCH_LIST_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const updateListState = (identifier, data = [], concat = false) => {
	return dispatch => {
		dispatch(fetchListSuccess(identifier, data, concat));
	};
};

export const resetListState = identifier => {
	return dispatch => {
		dispatch(fetchListSuccess(identifier));
	};
};

export const flattenFilters = filters => {
	const filtersStr = isFullArray(Object.keys(filters)) ? (
		Object.entries(filters).reduce((arr, map) => (
			// flatten filterproperties is passed in array
			isEmptyArray(map[1]) ? (
				arr
			) : isFullArray(map[1]) ? (
				[...arr, ...map[1].map(value => [map[0], value])]
			) : [...arr, ...[map]]
		), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	) : '';

	const filterString = '?' + [filtersStr].filter(str => str.length).join('&');

	return filterString;
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
** * * * * * * * * * * * * * * */
const fetchList = (identifier, path, settings = {}) => {
	const current = ListStatesCounter.increment(identifier);
	const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
	return async dispatch => {
		dispatch(fetchListStart(identifier));
		try {
			let data = await (
				isArray(path) ?
					Promise.all(path.map(p => events[method](p, bodyData))) :
					events[method](path, bodyData)
			);

			if (ListStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(transformer)) {
					data = transformer(data);
				}
				dispatch(fetchListSuccess(identifier, data, !!concat));
			}
		} catch (error) {
			if (ListStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(errorCallback)) {
					errorCallback(dispatch, error);
				} else {
					dispatch(fetchListFail(identifier, error));
				}
			}
		}
	};
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * *  * * * *
*  CATEGORIES *
* * * * * * * */
export const fetchCategories = (filters = 'none') => {
	const queryParams = isFullString(filters) ? `?filters=${filters}` : '';
	return fetchList('categories', `categories${isFullString(queryParams) ? queryParams : ''}`);
};



/* * * * * * * * * *
 * TICKET METHODS  *
 * * * * * * * * * */
export const fetchTicketDefects = (filters = null) => {
	const filtersStr = isFullArray(Object.keys(filters)) ? (
		Object.entries(filters).reduce((arr, map) => (
			// flattened filterproperties are passed in array
			isEmptyArray(map[1]) ? (
				arr
			) : isFullArray(map[1]) ? (
				[...arr, ...map[1].map(value => [map[0], value])]
			) : [...arr, ...[map]]
		), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
	) : '';

	return fetchList('ticketDefects', `ticketdefects${isEmptyString(filtersStr) ? '' : '?'}${filtersStr}`);
};

export const bookingTripCoordinates = (bookingId = null, tripId = null) => {
	return fetchList('bookingTripCoordinates', `bookings/${bookingId}/trips/${tripId}/coordinates`);
};

export const tripCoordinates = (tripId = null) => {
	return fetchList('tripCoordinates', `trips/${tripId}/coordinates`);
};

export const searchInstance = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';
	return fetchList('searchInstance', `autocompletes/instances${filterString}`);
};