import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	actions: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > * + *': {
			marginLeft: theme.spacing(2)
		}
	},
}));